import CheckCircle from "@mui/icons-material/CheckCircle";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Error from "@mui/icons-material/Error";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "@recare/core/consts";
import { generateRSButtonTrackName } from "@recare/core/validationSchemas/utils";
import Tooltip from "ds/components/Tooltip";
import { GREY_200, GREY_600 } from "ds/materials/colors";
import {
  border,
  dp,
  important,
  margin,
  padding,
  sizing,
} from "ds/materials/metrics";
import { SHADOW_BUTTON, SHADOW_BUTTON_HOVER } from "ds/materials/shadows";
import { FONT_SIZE_20 } from "ds/materials/typography";
import React, { forwardRef, useCallback, useRef } from "react";
import { useTracking } from "react-tracking";
import styled, { keyframes } from "styled-components";
import { ColorData, backgroundColors, setInvertion } from "./colors";
import {
  ConditionalQueryProgress,
  RSButtonColor,
  RSButtonIcon,
  RSButtonProps,
  RSButtonVariant,
} from "./index";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div<{
  color: "primary" | "secondary";
  parentHeight: number | undefined;
}>`
  border: ${(props) =>
    border({
      width: 4.5,
      color: props.theme.palette[props.color].light,
    })}; /* background */
  border-top: ${(props) =>
    border({
      width: 4.5,
      color: props.theme.palette[props.color].dark,
    })}; /* spinner */
  border-radius: 50%;
  width: ${(props) => dp(props.parentHeight ? props.parentHeight / 2.25 : 16)};
  height: ${(props) => dp(props.parentHeight ? props.parentHeight / 2.25 : 16)};
  animation: ${spin} 1s linear infinite;
`;

const IconWrap = styled.span<{ right: boolean }>`
  font-size: ${FONT_SIZE_20};
  display: flex;
  ${(props) =>
    props.right ? `margin-left: ${sizing(1)}` : `margin-right: ${sizing(1)}`};
`;

function getMaterialUiColor(color: RSButtonColor | "inherit" | undefined) {
  switch (color) {
    case "default":
      return "primary";
    case "inherit":
    case "primary":
    case "secondary":
      return color;
    default:
      return undefined;
  }
}

function getIcon(
  IconProp: RSButtonIcon | undefined,
  right: boolean,
  variant: RSButtonVariant,
  inverted?: boolean,
  loading?: ConditionalQueryProgress,
) {
  if (!IconProp && !loading) return null;

  let IconComponent = IconProp;
  let className = "icon";

  if (loading === QUERY_PROGRESS_SUCCEED) {
    IconComponent = CheckCircle;
    className = `${className}-succeed`;
  } else if (loading === QUERY_PROGRESS_FAILED) {
    IconComponent = Error;
    className = `${className}-error`;
  } else if (typeof IconProp === "string") {
    if (IconProp === "back") {
      IconComponent = ChevronLeft;
      className = `${className}-back`;
    } else if (IconProp === "forward") {
      IconComponent = ChevronRight;
      className = `${className}-forward`;
    }
  }

  const shouldGetOpacity =
    (variant === "text" && !inverted) ||
    (variant === "contained" && inverted) ||
    (variant === "outlined" && !inverted);

  if (IconComponent) {
    IconComponent = IconComponent as React.ComponentType<any>;
    return (
      <IconWrap right={right}>
        <IconComponent
          className={className}
          fontSize="inherit"
          style={shouldGetOpacity ? { opacity: 0.6 } : null}
        />
      </IconWrap>
    );
  }

  return null;
}

function Loading({
  children,
  color,
  loading,
}: {
  children: React.ReactNode;
  color?: RSButtonColor;
  loading?: ConditionalQueryProgress;
}) {
  const ref = useRef<HTMLDivElement>(null);
  if (!loading || loading !== QUERY_PROGRESS_PENDING) return <>{children}</>;
  return (
    <>
      <div style={{ display: "flex", visibility: "hidden" }}>{children}</div>
      <div
        ref={ref}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          color={color == "secondary" ? "secondary" : "primary"}
          parentHeight={ref.current?.clientHeight}
        />
      </div>
    </>
  );
}

export const hoverColorSelector = (
  color: RSButtonColor,
  inverted: boolean | undefined,
  variant?: RSButtonVariant | undefined,
): RSButtonColor => {
  if (inverted) {
    if (variant === "text") {
      return "white";
    }
    return `${color}-dark` as RSButtonColor;
  }

  if (color === "secondary") {
    return "default" as RSButtonColor;
  }
  if (variant === "text") {
    return `${color}-dark` as RSButtonColor;
  }
  return "white";
};

export const hoverBackgroundColorSelector = (
  color: RSButtonColor,
  inverted: boolean | undefined,
  variant?: RSButtonVariant | undefined,
): RSButtonColor => {
  if (inverted) {
    if (variant === "text") {
      return `${color}-dark` as RSButtonColor;
    }
    return "white";
  }

  if (variant === "contained") {
    return `${color}-dark` as RSButtonColor;
  }
  if (variant === "text") {
    return "white";
  }
  return color;
};

const RSButton = forwardRef<HTMLButtonElement, RSButtonProps>(
  (
    {
      ariaProps,
      buttonWrapperStyle,
      children,
      className,
      color = "default",
      disabled,
      id = "rs_button",
      inverted,
      LeftIcon,
      loading,
      onClick,
      RightIcon,
      size = "medium",
      style,
      tabIndex,
      tooltip,
      type,
      variant,
    },
    ref,
  ) => {
    const { trackEvent } = useTracking();
    const theme = useTheme();
    const borderColor = ["primary", "secondary", "success", "error"].includes(
      color,
    );

    const getSx = useCallback(() => {
      {
        // Base color setup:
        let colorData: ColorData = {
          color:
            color === "secondary"
              ? theme.palette.common.black
              : theme.palette.common.white,
          borderColor: borderColor
            ? backgroundColors(theme)[color]
            : theme.palette.common.black,
          backgroundColor: backgroundColors(theme)[color],
          boxShadow: variant === "contained" ? SHADOW_BUTTON : "",
        };

        // setup invertion
        colorData = setInvertion(colorData, variant, inverted);

        colorData = {
          ...colorData,
          "&:hover": {
            color:
              backgroundColors(theme)[
                hoverColorSelector(color, inverted, variant)
              ],
            borderColor: borderColor
              ? backgroundColors(theme)[color]
              : theme.palette.common.black,
            outline:
              variant === "text" && !inverted
                ? `${dp(1)} solid ${backgroundColors(theme)[color]}`
                : undefined,
            backgroundColor:
              backgroundColors(theme)[
                hoverBackgroundColorSelector(color, inverted, variant)
              ],
            boxShadow: variant === "contained" ? SHADOW_BUTTON_HOVER : "",
          },
        };

        // in case of text remove background
        if (variant === "text" && !inverted)
          colorData = {
            ...colorData,
            backgroundColor: "transparent",
          };

        if (disabled) {
          colorData = {
            ...colorData,
            color: important(GREY_600),
            backgroundColor: important(GREY_200),
            borderWidth: important("0"),
          };
        }

        return {
          ...colorData,
          cursor: "pointer",
          margin: margin(1),
          minHeight: sizing(4.5),
          minWidth: sizing(8),
          width: "fit-content",
          pointerEvents: loading === QUERY_PROGRESS_PENDING ? "none" : "auto",
          position: "relative",
          borderRadius: variant !== "contained" ? dp(2) : dp(4),
          // required for accessiblity to ensure focus outline is visible
          outlineOffset: dp(1),
          ...style,
        };
      }
    }, [
      color,
      disabled,
      inverted,
      loading,
      theme,
      variant,
      ...Object.values(style ?? {}),
    ]);

    return (
      <Tooltip title={tooltip}>
        <div
          data-testid="rs-button-tooltip"
          style={{
            // 1px additional padding for the outline offset
            padding: padding(1 / 8),
            ...buttonWrapperStyle,
          }}
        >
          <Button
            {...ariaProps}
            className={className}
            color={getMaterialUiColor(color)}
            data-testid={id}
            disabled={disabled || loading === QUERY_PROGRESS_PENDING}
            onClick={(e) => {
              trackEvent({ name: generateRSButtonTrackName({ id }) });
              onClick(e);
            }}
            ref={ref}
            size={size}
            sx={getSx()}
            tabIndex={tabIndex}
            type={type ?? "button"}
            variant={variant}
          >
            <Loading loading={loading} color={color}>
              {getIcon(LeftIcon, false, variant, inverted)}
              {children}
              {getIcon(RightIcon, true, variant, inverted, loading)}
            </Loading>
          </Button>
        </div>
      </Tooltip>
    );
  },
);

export default RSButton;
