import { A11yStatusUpdate } from "@recare/app/src/containers/A11yStatusProvider/A11yStatusContext";
import {
  ACCOUNT_ROLES,
  ACCOUNT_TABLE_ACTIONS,
  ACCOUNT_TYPE,
  ACTIVE_PROVIDER_SEARCHES,
  ACTIVE_PROVIDER_SEARCH_VERSIONS,
  ACTIVE_SEARCH_MERGE_STATUSES,
  ALGOLIA_INDICES,
  ASSESSMENT_SLUGS,
  ASSESSMENT_VARIANTS,
  AUCTION_STEPPER_STEPS,
  CAREPROVIDER_TAB_TYPE_DIRECT,
  CONSULTANT_STATUSES,
  COUNTRY_DE,
  COUNTRY_FR,
  FILE_ERROR_TYPE,
  FILE_SHARE_MODE_KINDS,
  FILE_TABLE_ACTIONS,
  GLOSSARY_SECTIONS,
  GRANT_STATUSES,
  INDEX_DB_SESSION_KEYS,
  INDEX_DB_SESSION_KEYS_VERSION,
  INDEX_DB_TABLES,
  LEGAL_DOCUMENTS,
  LOGO_COLOR_SCHEMA,
  NEGATIVE_DIFF,
  ORIGIN,
  PAGINATION_RESULT_COUNTS,
  POSITIVE_DIFF,
  PRODUCT_FEATURES,
  PRODUCT_FEATURE_STATUSES,
  PROVIDER_SEARCHES,
  PROVIDER_SEARCH_VERSIONS,
  REDIRECT_REASON_GENERIC_ERROR,
  REDIRECT_REASON_LINK_EXPIRED,
  REDIRECT_REASON_NOT_AUTHORIZED,
  REQUEST_CHIP_STATUSES,
  REQUEST_FORM_FIELDS,
  SEALD_ENCRYPTION_ACCESS_STATUS,
  SEARCH_ACTION_GENERATE_AHB_FORMS,
  SEARCH_ACTION_PRINT_DISCHARGE,
  SEARCH_MERGE_BANNERS,
  SEARCH_MERGE_STATUSES,
  SEARCH_TABLE_ROW_COUNTS,
  SEARCH_TABLE_TABLES,
  SEARCH_TYPES,
  SHORT_TERM_GRANT_STATUSES,
  SIMPLE_DIFF,
  SORTING_MAP,
  TABLES_PAYLOAD,
  TABLE_PAYLOAD_ACCEPTED,
  TABLE_PAYLOAD_CONTACTED,
  TABLE_PAYLOAD_DECLINED,
  TABLE_PAYLOAD_FILTERED,
  TABLE_PAYLOAD_POTENTIAL,
  TABLE_PAYLOAD_REJECTED,
  TABLE_PAYLOAD_VALIDATED,
  WEBSOCKET_STATUS,
  WEEKDAYS,
} from "@recare/core/consts";
import { ALLOWED_EXTENSIONS_HIS } from "@recare/core/model/files/config";
import { TrackingProp } from "react-tracking";
import {
  ALGOLIA_ANALYTICS_NAMES,
  ALL_MESSENGER_TABS,
  APP_KIND,
  APP_VERSION_NAMES,
  ASSESSMENT_SLUG_ELECTIVE_REHAB_INFO,
  ASSESSMENT_SLUG_ELECTIVE_REHAB_MEDICAL,
  ASSESSMENT_SLUG_HOME_CARE_INFO,
  ASSESSMENT_SLUG_HOME_CARE_SEARCH,
  ASSESSMENT_SLUG_MEDICAL_SUPPLIES_CATALOGUE,
  ASSESSMENT_SLUG_MEDICAL_SUPPLIES_INFO,
  ASSESSMENT_SLUG_MOBILE_SHORT,
  ASSESSMENT_SLUG_SHORT_REHAB_INFO,
  ASSESSMENT_SLUG_SHORT_REHAB_MEDICAL,
  ASSESSMENT_SLUG_TRANSPORT_INFO,
  ASSESSMENT_SLUG_TRANSPORT_SEARCH,
  CANDIDATES_STATUS,
  CAPACITY_STATUSES,
  CAREPROVIDER_DASHBOARD_TAB,
  CAREPROVIDER_TAB_TYPE_ATTRIBUTED,
  CAREPROVIDER_TAB_TYPE_IN_PROGRESS,
  CAREPROVIDER_TAB_TYPE_NEW,
  CAREPROVIDER_TAB_TYPE_OTHER,
  CARESEEKER_TAB_TYPE_ARCHIVED,
  CARESEEKER_TAB_TYPE_DRAFT,
  CARESEEKER_TAB_TYPE_FINISHED,
  CARESEEKER_TAB_TYPE_PATIENTS,
  CLINIC_TODO_DASHBOARD_TAB,
  COST_PAYER_TYPES,
  DISABLED_SEARCH_ACTION_TYPES,
  EXTENSIONS,
  FILE_CATEGORIES,
  FORM_YES_NO_NEGATIVE,
  FORM_YES_NO_POSITIVE,
  NO_CAPACITY_FOR_CARELEVEL,
  NO_CAPACITY_FOR_GENDER,
  NO_CAPACITY_FOR_INFECTION,
  NO_CAPACITY_FOR_ROOM,
  NO_CAPACITY_FOR_SERVICE,
  NO_CAPACITY_FOR_SOLUTION,
  NO_CAPACITY_FOR_SPECIALIZATION,
  QUERY_PROGRESS_TYPES,
  RECEIVER_DASHBOARD_FILTERS,
  REHABILITATION_PAYMENT_OPTIONS,
  REQUEST_ACTION_TYPES,
  REQUEST_ACTION_UNDO_TYPES,
  REQUEST_CONTEXT_DISABLED_TYPES,
  SEALD_FLOWS,
  SEARCH_ACTIONS,
  SEARCH_ACTION_VARIANTS,
  SEARCH_MERGE_STOP_SEARCH_PANELS,
  SENDER_DASHBOARD_FILTERS,
} from "./consts";
import { CONSULTANT_EVENTS, EVENTS } from "./consts/events";
import { OntologyType } from "./model/utils/ontologies";

export type { Recipients } from "@seald-io/sdk/lib/main";

export type TrackEventFn<P = unknown> = TrackingProp<P>["trackEvent"];

export type ValueOf<T> = T[keyof T];

export type NonNullable<T> = Exclude<T, null | undefined>; // Remove null and undefined from T
export type NonUndefined<T> = T extends undefined ? never : T;
export type NonNullableObj<T> = {
  [K in keyof T]-?: NonNullable<T[K]>;
};

export type Falsy = null | undefined | false | 0 | -0 | 0n | "";
export type Truthy<T> = T extends Falsy ? never : T;

export type NeverObj<T> = { [K in keyof T]?: never };
export type NullableObj<T> = { [K in keyof T]: T[K] | null };
export type NullOrUndefinableObj<T> = { [K in keyof T]?: T[K] | null };
export type DeepNullableObj<T> = {
  [K in keyof T]: DeepNullableObj<T[K]> | T[K] | null;
};
export type DeepPartial<T> = {
  [K in keyof T]?: DeepPartial<T[K]> | T[K] | undefined;
};

type DeepReadonlyArray<T> = ReadonlyArray<DeepReadonly<T>>;

export type DeepReadonly<T> = T extends Array<infer A>
  ? DeepReadonlyArray<A>
  : T extends object
  ? Readonly<{ readonly [K in keyof T]: DeepReadonly<T[K]> }>
  : Readonly<T>;

type DistributiveValues<T extends Record<string, any>> = T extends T
  ? T[keyof T]
  : never;

export type InnerValues<
  T extends Record<keyof T, object>,
  K extends keyof T,
> = DistributiveValues<T[K]>;

export type EitherOr<T, U, Base = {}> =
  | (Base & T & Partial<Record<keyof U, never>>)
  | (Base & U & Partial<Record<keyof T, never>>);

// FIXME
export type ToType = any;
// FIXME
export type AnyObject = Record<string, any>;
// FIXME
export type AnyFunction = (...args: Array<ToType>) => ToType;

type ValidationInfo = {
  infos: AnyObject | string | boolean | undefined;
};

export type ApolloCacheData = AnyObject;

export type Validation = ValidationInfo | boolean;

export type Country = typeof COUNTRY_DE | typeof COUNTRY_FR;
export type SupportedLanguage = "de" | "en" | "fr";

export type Settings = {
  language?: SupportedLanguage | undefined;
};

export type QueryProgress = (typeof QUERY_PROGRESS_TYPES)[number];

export type InputWidthType =
  | "CHECKBOX"
  | "FULL_WIDTH"
  | "ISLAND"
  | "NESTED_CHECKBOX"
  | "SMALL"
  | "VERY_SMALL";

export type GeneratedFormsType =
  | "mobile-short"
  | "transport-info"
  | "transport-search";

export type AssessmentVariantType = ValueOf<typeof ASSESSMENT_VARIANTS>;

export type BooleanAsNumber =
  | typeof FORM_YES_NO_NEGATIVE
  | typeof FORM_YES_NO_POSITIVE;

export type SortOrder = "asc" | "desc";

// Some properties of the ReduxStore type might not be typed yet or only partially typed.
export type ReduxStore = {
  _persist?: {
    rehydrated?: boolean;
    version?: number;
  };
  appState: {
    cacheLoaded?: boolean;
    lastRequestFailed?: boolean | null;
  };
  auth: {
    _persist?: {
      rehydrated?: boolean;
      version?: number;
    };
    active_roles: number[];
    careprovider?: number;
    careprovider_name?: string;
    careseeker?: number;
    careseeker_name?: string;
    country: string;
    credentials?: {
      expiration?: number;
      token?: string;
      token_type?: string;
    };
    identification: {
      account: Account;
      active_roles?: CareproviderRoles | CareseekerRoles;
      careprovider_roles?: CareproviderRoles[];
      careseeker_roles?: CareseekerRoles[];
      privateKey: string;
    };
  };
  networkState: {
    state: string;
  };
  settings: {
    language: SupportedLanguage;
  };
};

export type TabConfigType<
  Tab extends GenericDashboardTab,
  TabKey extends GenericDashboardTabKey,
> = {
  Icon?: React.ComponentType<any> | null | undefined;
  archived?: boolean;
  key: Tab;
  title: string;
  tour?: TourElement;
  value: TabKey;
};

export type RequestCount = {
  count: number;
  hash?: string;
  new: number;
};

export type CareproviderDashboardCounts = Record<CareproviderTab, RequestCount>;
export type ClinicDashboardCounts = Record<CareseekerTab, RequestCount>;

export type Counts = Partial<
  CareproviderDashboardCounts & ClinicDashboardCounts
>;

export type CareproviderMetrics = {
  accept_count?: number;
  attributed_count?: number;
  avg_time_to_click?: number;
  avg_time_to_open?: number;
  avg_time_to_response?: number;
  local_accept_count?: number;
  local_attributed_count?: number;
  local_maybe_count?: number;
  local_request_count?: number;
  local_response_count?: number;
  maybe_count?: number;
  request_count?: number;
  response_count?: number;
};

export type OauthAuthenticationInput = {
  code: string;
  code_verifier: string;
  redirect_uri: string;
};

export type Contact = {
  academic_title?: number | null | undefined;
  email?: string;
  first_name?: string;
  gender?: number;
  landline_number?: string;
  last_name?: string;
  mobile_number?: string;
  position?: string;
  salutation?: number;
  send_emails?: boolean;
  title?: number | null | undefined;
};

type CompanyStationAddress = {
  address?: string;
  city?: string;
  zipcode?: string;
};

export type CompanyStation = {
  address?: CompanyStationAddress;
  central_email?: string;
  central_phone_number?: string;
  corona_service_helper?: boolean;
  facilities?: Array<number>;
  fax_number?: string;
  id: number;
  is_complete: boolean;
  name?: string;
  other_remarks?: string;
  profile?: {
    accepted_age?: Interval;
    accepted_weight?: Interval;
    radius_in_meter?: number;
    room_types?: Array<number>;
  };
  services?: Array<number>;
  website?: string;
};

export type Stations = {
  [key: string]: CompanyStation;
};

export type TeamMember = {
  academic_title?: number;
  accessible_stations?: Array<number>;
  careprovider_roles?: boolean;
  email?: string;
  email_requests?: boolean;
  first_name?: string;
  id: string;
  is_complete: boolean;
  last_name?: string;
  mobile_phone?: string;
  phone?: string;
  position?: string;
  salutation?: number;
  status?: number;
  title?: number;
};

export type TeamMembers = {
  [key: string]: TeamMember;
};

export type OnboardingBlob = {
  all_process_completed?: boolean;
  stations: Stations;
  team_members: TeamMembers;
};

export type Company = {
  address?: CompanyAddress;
  careproviders?: Array<Careprovider>;
  careseekers?: Array<Careseeker>;
  companies?: Array<Company>;
  company_type?: number;
  force_id?: string;
  id: number;
  ik_numbers?: Array<number>;
  name: string;
  number_of_beds?: number;
  onboarding_blob?: OnboardingBlob | undefined;
  parent_company?: Company;
  parent_company_id?: number | null;
  send_to_company_first?: boolean;
  status?: number;
};

export type CostPayerType = (typeof COST_PAYER_TYPES)[number];

export type Insurance = {
  careseeker_id?: number;
  id: number;
  integration_platform?: number;
  is_active?: boolean;
  name: string;
  type?: number;
};

export type TermsAndConditions = {
  date?: number;
  version?: string;
};

export type Capacity = {
  available_date?: number | null;
  capacity_confirmed?: boolean;
  capacity_status?: CapacityStatus | null;
  has_capacity?: boolean;
  updated_at?: number | null;
};

export type NoCapacityInfo = {
  careprovider_id: number;
  expiration: number;
  id: number;
  no_capacity_reasons: Array<NoCapacityReason>;
};

export type CareproviderProfile = {
  accepted_age?: Interval;
  accepted_weight?: Interval;
  additional_costs?: string;
  capacity?: Capacity;
  cardiovascular_equipment?: Array<number>;
  caregiver_genders?: Array<number>;
  confessions?: Array<number>;
  digestive_endoscopy?: Array<number>;
  health_insurance_requirement?: number;
  imagery?: Array<number>;
  languages?: Array<number>;
  opt_out_b2c?: boolean;
  other_technical_equipment?: Array<number>;
  radius_in_meter?: number;
  room_types?: Array<number>;
  social_worker_on_site?: boolean;
  supported_health_insurance_types?: Array<number>;
  whitelisted_zipcodes?: Array<string>;
};

export type CareproviderAddress = {
  address?: string;
  city?: string;
  country?: Country;
  federal_state?: string;
  latitude?: number;
  longitude?: number;
  street?: string;
  street_number?: string;
  zip_code?: string;
};

export type CompanyAddress = {
  address?: string;
  city?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  zipcode?: string;
};

export type CareproviderOnboardingStats = {
  active_since?: number;
  onboarding_source?: number;
};

export type CareproviderActivities = {
  invite_contacts_popup?: boolean;
};

export type SealdConfig = {
  seald_flow?: number;
  seald_notes?: string;
};

export type CareproviderConfig = {
  disable_seald?: boolean;
  seald_config?: SealdConfig;
  seald_only_encryption?: boolean;
};

export type EventLocation =
  | "acp"
  | "documentation"
  | "eligibility"
  | "lastevent"
  | "messenger";

export type FreeBed = {
  coronavirus?: boolean;
  id: number;
  specializations?: Specializations;
};

export type Assignee = Pick<
  Account,
  "__typename" | "first_name" | "id" | "last_name"
>;

export type EncryptionContext = {
  auction_request_id?: number;
  careprovider_id?: number;
  careseeker_id?: number;
  file_id?: number;
  id: number;
  patient_id?: number;
  seald_display_id: string;
  seald_id: string;
  seald_type: number;
};

export type SealdAccessPayload = {
  account_id?: number;
  careprovider_id?: number;
  careseeker_id?: number;
  encrypt_for_self?: boolean;
  seald_id: string;
};

export type PendingSealdAccess = {
  account_id?: number;
  careprovider_id?: number;
  careseeker_id?: number;
  created_at: string;
  id: number;
  seald_encryption_context: EncryptionContext;
};

export type Extension = {
  __typename: "Extension";
  active_since: number | null;
  connected: boolean;
  integration_type: keyof typeof EXTENSIONS;
};

export type ProviderSearchInfo = {
  eligible?: boolean;
  state?: ProviderSearch;
};

export type Careprovider = {
  __typename: string;
  accounts?: Array<Account> | null;
  activities?: CareproviderActivities;
  address?: CareproviderAddress;
  assignee?: Account;
  care_provider_profile?: CareproviderProfile;
  central_email?: string;
  central_phone_number?: string;
  company?: Company | null;
  config?: CareproviderConfig;
  contacts?: Array<Contact>;
  data_processing_agreement?: CareproviderDpaFile;
  distance?: string;
  duplicated_from?: Careprovider;
  email_requests?: boolean;
  email_summary?: boolean;
  events?: Array<Event>;
  extensions?: Extension[];
  facilities?: Array<number>;
  fax_number?: string;
  feedback?: string;
  files?: CareproviderFiles;
  id: number;
  inactive_reason?: number;
  lead_id?: string;
  metrics?: CareproviderMetrics;
  name?: string;
  new?: boolean;
  no_capacities?: Array<NoCapacityInfo>;
  onboarding?: CareproviderOnboarding;
  onboarding_stats?: CareproviderOnboardingStats;
  opportunity_id?: string;
  other_remarks?: string;
  patient_type?: SearchType;
  pending_messages?: number;
  ppm_receiver_id?: number;
  prerequisites?: Array<number>;
  profile?: CareproviderProfile;
  provider_search_info?: ProviderSearchInfo;
  receive_fax?: boolean;
  receive_sms?: boolean;
  related_careseekers?: Array<RelatedCareseeker>;
  seald_encryption_context?: EncryptionContext;
  services?: Array<number>;
  specializations?: Specializations;
  status?: number;
  terms_and_conditions?: TermsAndConditions;
  thumbnail_url?: string;
  token?: string;
  trigger_ppm_self_onboarding?: boolean;
  website?: string;
};

export type StopReason = {
  hideInForm?: boolean;
  id: string;
  label: JSX.Element | string;
  value: number;
};

export type Address = {
  address?: string;
  city?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  street?: string;
  street_number?: string;
  zipcode?: string;
};

export type WoundCare = {
  description?: string;
};

export type PsychiatricCare = {
  description?: string;
};

export type Companionship = {
  companionship_grant?: boolean;
  description?: string;
};

export type Breathing = {
  oxygen_therapy?: string;
  specialised_intensive_nurse?: boolean;
  tracheostomy?: string;
};

export type Dialysis = {
  hemodialysis?: string;
  peritoneal_dialysis?: string;
};

export type BaseCare = {
  companionship_grant?: boolean;
  description?: string;
};

export type HelpAtHome = {
  description?: string;
};

export type CompressionCare = {
  description?: string;
};

export type Medication = {
  blood_thinner?: string;
  description?: string;
};

export type Food = {
  dysphagia?: string | undefined;
  enteral_nutrition?: string | undefined;
  food_help?: Array<number>;
  meals?: Array<number>;
  parenteral_nutrition?: string | undefined;
};

export type IncontinenceHelp = {
  fecal_incontinence?: string | undefined;
  incontinence_help_type?: number;
  requires_material_supplies?: boolean;
  urinary_incontinence?: string | undefined;
};

export type Injection = {
  description?: string;
};

export type PatientNeedSupport = {
  body_care?: boolean;
  dressing?: boolean;
  eating?: boolean;
};

export type PatientNeedMonitoring = {
  circulation?: string;
  metabolism?: string;
  respiration?: string;
};

export type AccesDrainages = {
  central_venous_catheter?: string;
  drainage?: string;
  gastric_tube?: string;
  intravenous_injection?: string;
  other_access_drainages?: string;
  peripheral_venous_catheter?: string;
  permanent_catheter?: string;
  suprapubic_catheter?: string;
  tracheal_cannula?: string;
};

export type PatientNeeds = {
  __typename?: string;
  access_drainages?: AccesDrainages;
  base_care?: BaseCare;
  basic_medical_care?: { description?: string };
  breathing?: Breathing;
  companionship?: Companionship;
  compression?: CompressionCare;
  dialysis?: Dialysis;
  food?: Food;
  help_at_home?: HelpAtHome;
  important_information?: string | null | undefined;
  incontinence?: IncontinenceHelp;
  injection?: Injection;
  medication?: Medication;
  monitoring?: PatientNeedMonitoring;
  nutrition?: Food;
  palliative?: Palliative;
  psychiatric_care?: PsychiatricCare;
  remarks?: string;
  static_care_disabled_patients?: { description?: string };
  support?: PatientNeedSupport;
  wound_care?: WoundCare;
};

export type DependencyDiagnosis = {
  agitated?: number;
  agitated_description?: string;
  barthel_index?: string;
  barthel_index_list?: BarthelIndex[];
  dependency_level?: number;
  early_rehab_barthel_index?: string;
  early_rehab_barthel_index_list?: RehabIndex[];
  has_help_at_home?: number;
  requires_support_with_feeding?: number;
  requires_walking_aid?: number;
  returning_home_short_term?: number;
};

export type InfectionAndGerms = {
  __typename?: string;
  clostridien?: boolean;
  description?: string;
  four_mrgn?: boolean;
  mrsa?: boolean;
  other?: string;
  remarks?: string;
  requires_isolation?: boolean;
  three_mrgn?: boolean;
  vre?: boolean;
};

export type HospitalStay = {
  complications?: string;
  doctor_in_charge_in_hospital?: string;
  doctor_in_charge_in_hospital_phone?: string;
  geriatric_early_stage?: string;
  operation_date?: number;
  operation_description?: string;
  therapy_at_hospital?: string;
};

export type LimitedCapacity = {
  limited_cognitive_capacity: string | null | undefined;
  limited_physical_capacity: string | null | undefined;
  limited_psychological_capacity: string | null | undefined;
  limited_social_behaviour: string | null | undefined;
  other: string | null | undefined;
};

export type RehabilitationPotential = {
  description?: string;
  evaluation?: number;
};

export type PhysicalDisabilities = {
  assistive_equipment?: string;
  auditive_impairment?: string;
  existing_medical_supplies?: string;
  load_carrying_ability?: string;
  mobility?: number;
  mobility_additional_information?: string;
  orthese?: string;
  prescribed_medical_supplies?: string;
  prothese?: string;
  required_medical_supplies?: string;
  visual_impairment?: string;
};

export type CarePreferences = {
  accompanying_person?: boolean;
  barrier_free_room?: boolean;
  room_type?: number;
  specialist_doctor?: boolean;
};

export type Solution = number;
export type Solutions = Array<Solution>;
export type Specialization = number;
export type Specializations = Array<Specialization>;

export type Winner = {
  auction_request?: AuctionRequest;
  auction_request_id?: number;
  careprovider?: Careprovider;
  careprovider_name?: string;
  created_at?: number;
  external_provider?: boolean;
  found_by?: number;
  solution?: Solution;
  specialization?: Specialization;
  unknown_provider?: boolean;
  updated_at?: number;
};

export type CancelAuction = {
  description?: string;
  reason?: number;
};

export type PickUpTime = {
  hour: number;
  minute: number;
};

export type PickUpTimes = {
  end: PickUpTime;
  start: PickUpTime;
};

export type MonitoringNeeds = {
  ecg: boolean;
  pulse_oximeter: boolean;
};

export type AuctionProfile = {
  has_transitional_care?: boolean;
  is_elective?: boolean;
  is_visually_impaired?: number;
  monitoring_needs?: MonitoringNeeds;
  needs_oxygen?: number;
  no_site_support_required?: boolean | null | undefined;
  patient_type_updated?: boolean;
  pick_up?: PickUpTimes;
  search_destination?: Location;
  search_location?: Location;
};

export type SearchActionType = (typeof SEARCH_ACTIONS)[number];
export type DisabledSearchActionType =
  (typeof DISABLED_SEARCH_ACTION_TYPES)[number];
export type SearchActionVariantType = (typeof SEARCH_ACTION_VARIANTS)[number];

export type SearchAction = {
  action_type: SearchActionType;
  context?: {
    disabled?: DisabledSearchActionType;
    variant?: SearchActionVariantType;
  };
};

export type SearchActions = SearchAction[];

type FrontEndOnlyActions =
  | typeof SEARCH_ACTION_GENERATE_AHB_FORMS
  | typeof SEARCH_ACTION_PRINT_DISCHARGE;

export type SearchActionsWithHandlers = Exclude<
  SearchActionType,
  FrontEndOnlyActions
>;

export type TransitionalCare = {
  created_at?: number;
  form_data?: EncryptedField | null;
  id?: number;
  updated_at?: number;
};

export type Auction = {
  __typename: string;
  action_required_by_user?: boolean;
  additional_specializations?: Specializations;
  all_contacted?: boolean;
  all_negative?: boolean;
  assessment_completed?: boolean;
  assessment_variant?: AssessmentVariantType;
  cancel_auction?: CancelAuction;
  cancel_validation_reason?: string;
  candidates_status?: CandidatesStatus;
  country_wide_search?: boolean;
  created_at?: number;
  id: number;
  is_stale?: boolean;
  last_status_change?: number;
  max_accepts?: boolean;
  medical_supplies?: MedicalSupplies;
  new_responses?: number;
  patient: Patient;
  patient_id?: number;
  payers?: Payers | undefined;
  pending_requests?: number;
  profile?: AuctionProfile;
  radius_in_meter?: number;
  request_count?: number;
  requests?: Array<AuctionRequest>;
  reseted?: boolean;
  seald_encryption_context?: EncryptionContext;
  search_actions?: SearchActions;
  search_type: SearchType;
  seen_by_user?: boolean;
  services?: Array<number>;
  session_key_context?: SessionKeyContext;
  share_patient_data_automatically?: boolean;
  solutions?: Solutions;
  specializations?: Array<Specialization>;
  start_date?: number;
  start_date_flexible?: boolean;
  status?: number;
  transitional_care?: TransitionalCare;
  winner?: Winner;
};

export type WarningsPayload = {
  auction_request?: AuctionRequest;
  careprovider: Careprovider;
  solution_filter_reasons: SolutionFilterReasons;
};

export type AuctionNoPatient = Omit<Auction, "patient">;

export type AccountRole = (typeof ACCOUNT_ROLES)[number];

export type CareseekerInRole = Pick<
  Careseeker,
  "__typename" | "address" | "id" | "name" | "patient_types" | "status"
>;

export type CareseekerRoles = {
  careseeker?: CareseekerInRole;
  id?: number;
  roles?: AccountRole[];
};

export type CareproviderInRole = Pick<
  Careprovider,
  "address" | "id" | "name" | "status"
>;

export type CareproviderRoles = {
  careprovider?: CareproviderInRole;
  id?: number;
  roles?: AccountRole[];
};

export type Roles = {
  admin_roles?: number[];
  careprovider_roles?: Array<CareproviderRoles>;
  careseeker_roles?: Array<CareseekerRoles>;
};

export type Activity = {
  updated_at?: number;
};

export type Activities = {
  add_members_cta?: Activity;
};

export type Rating = {
  score: number;
  timestamp: number;
};

export type CareseekerActions = {
  create_patients: boolean;
  show_drafts: boolean;
};

export type SealdEncryptionAccessStatus =
  (typeof SEALD_ENCRYPTION_ACCESS_STATUS)[number];

export type AccountType = ValueOf<typeof ACCOUNT_TYPE>;

export type AccountMutationArgs = Omit<
  NullableObj<Account>,
  "id" | "__typename"
>;

export type Account = {
  __typename: string;
  academic_title?: number;
  accessible_stations?: Array<number>;
  account_type?: AccountType;
  action?: {
    context?: {
      careprovider_id?: number;
      careseeker_id?: number;
    };
    type: number;
    version?: string;
  };
  activities?: Activities;
  browser_notifications_disabled?: boolean;
  careprovider_roles?: boolean;
  created_at?: number;
  crypto_keys?: RCryptoKey;
  email?: string;
  email_requests?: boolean;
  email_summary?: boolean;
  events?: Array<Event>;
  experiments?: Array<ExperimentAttribution>;
  fax_number?: string;
  first_login?: number;
  first_name?: string;
  gender?: number;
  id: number;
  intercom_id?: string;
  is_complete?: boolean;
  last_login?: number;
  last_name?: string;
  last_password_change?: number;
  mobile_devices?: Array<{
    account_id: number;
    device_token?: string;
    id: string;
    type: string;
  }>;
  mobile_phone?: string;
  notes?: string;
  phone?: string;
  position?: string;
  public_key?: string;
  ratings?: Array<Rating>;
  receive_sms?: boolean;
  roles?: Roles;
  salutation?: number;
  seald_database_key?: string;
  seald_encryption_status?: SealdEncryptionAccessStatus;
  seald_registered?: boolean;
  seald_test_identity?: string;
  seald_two_man_rule_key?: string;
  seald_user_license_token?: string;
  status?: number;
  title?: number;
};

export type AccountInIdentification = Pick<
  Account,
  | "__typename"
  | "account_type"
  | "email"
  | "first_name"
  | "id"
  | "last_name"
  | "phone"
>;

export type CareseekerConfig = {
  activate_gateway_debug_mode?: boolean;
  activate_parallel_search?: boolean;
  activate_search_merge?: boolean;
  activate_ssl_hl7_connector?: boolean;
  auto_validate?: boolean;
  blacklisted_assessment_fields?: Array<string>;
  deactivate_received_files?: boolean;
  deactivate_smart_suggestions?: boolean;
  disable_seald?: boolean;
  filter_on_social_worker?: boolean;
  ip_range?: string;
  jwt_expiration_in_minutes?: number;
  on_premise_archive_integration_enabled?: boolean;
  on_premise_authorization_token?: string;
  on_premise_domain_ssl?: string;
  on_premise_file_sync?: boolean;
  seald_config?: SealdConfig;
  seald_only_encryption?: boolean;
  use_kis_integration?: boolean;
  whitelisted_email_domains?: Array<string>;
  whitelisted_home_care_specializations?: Array<number>;
  whitelisted_hospital_specializations?: Array<number>;
  whitelisted_reha_specializations?: Array<number>;
};

export type CareseekerModule = {
  onboarding_email_link?: string;
  onboarding_radius_km?: number;
  onboarding_requirements?: string;
  prio_list_activation_target?: number;
  start_date?: number;
  status: number;
};

export type OnpremiseConfigInput = Pick<
  OnpremiseConfig,
  | "activate_gateway_debug_mode"
  | "config"
  | "name"
  | "on_premise_domain_ssl"
  | "on_premise_domain"
  | "on_premise_file_sync"
  | "should_restart"
  | "token"
>;

export enum OnpremFieldStatus {
  Imported = "imported",
  Empty = "empty",
  Fail = "fail",
}

export enum OnpremImportStatus {
  Success = "success",
  NotFound = "notfound",
}

export type OnPremImport = {
  account_id: number | undefined;
  admit_date_time: OnpremFieldStatus;
  allergies: OnpremFieldStatus;
  anamnesis: OnpremFieldStatus;
  attending_doctor: OnpremFieldStatus;
  barthel_index: OnpremFieldStatus;
  birth_date: OnpremFieldStatus;
  care_level: OnpremFieldStatus;
  careseeker_id: number;
  consulting_doctor: OnpremFieldStatus;
  created_at?: number;
  discharge_date_time: OnpremFieldStatus;
  evolution: OnpremFieldStatus;
  external_id: string;
  first_name: OnpremFieldStatus;
  gender: OnpremFieldStatus;
  guardians: OnpremFieldStatus;
  height: OnpremFieldStatus;
  id?: number;
  import_status: OnpremImportStatus;
  infections: OnpremFieldStatus;
  insurance_name: OnpremFieldStatus;
  insurance_number: OnpremFieldStatus;
  last_name: OnpremFieldStatus;
  main_diagnoses: OnpremFieldStatus;
  medical_history: OnpremFieldStatus;
  medical_instructions: OnpremFieldStatus;
  patient_address: OnpremFieldStatus;
  patient_city: OnpremFieldStatus;
  patient_postal_code: OnpremFieldStatus;
  recommended_routine: OnpremFieldStatus;
  relatives: OnpremFieldStatus;
  secondary_diagnoses: OnpremFieldStatus;
  station: OnpremFieldStatus;
  weight: OnpremFieldStatus;
};

export type OnpremiseConfig = {
  __typename: "OnpremiseConfig";
  activate_gateway_debug_mode?: boolean;
  activate_ssl_hl7_connector?: boolean;
  careseekers?: Careseeker[];
  config?: AnyObject & { __typename: "Config"; field_mapping?: AnyObject };
  hl7_version?: "hl7_2" | "fhir";
  id: number | null;
  name?: string;
  on_premise_authorization_token?: string;
  on_premise_domain?: string;
  on_premise_domain_ssl?: string;
  on_premise_file_sync?: boolean;
  should_restart?: boolean;
  token?: string;
};

export type CareseekerMutation = Partial<Careseeker> & {
  __typename: "Careseeker";
  on_premise_config?: { id: OnpremiseConfig["id"] };
};

export enum CareseekerModuleName {
  care = "care",
  hospital = "hospital",
  medical_supplies = "medical_supplies",
  reha = "reha",
  transport = "transport",
}

export type CareseekerModules = {
  [CareseekerModuleName.care]: CareseekerModule;
  [CareseekerModuleName.hospital]: CareseekerModule;
  [CareseekerModuleName.medical_supplies]: CareseekerModule;
  [CareseekerModuleName.reha]: CareseekerModule;
  [CareseekerModuleName.transport]: CareseekerModule;
};

export type Careseeker = {
  __typename: string;
  accounts?: Array<Account>;
  activate_on_premise?: boolean | null;
  address?: Address;
  banking_details?: BankingDetails;
  central_email?: string;
  code?: string;
  company?: Company;
  company_id?: number;
  config?: CareseekerConfig;
  contact_name?: string;
  contact_phone?: string;
  contacts?: any;
  created_at?: number;
  events?: Array<Event>;
  external_id?: string;
  force_id?: string;
  id: number;
  intercom_id?: string;
  modules?: CareseekerModules;
  name?: string;
  notes?: string;
  number_of_beds?: number;
  on_premise_config?: OnpremiseConfig;
  on_premise_config_id?: number | null;
  patient_types?: Array<SearchType>;
  ppm_sender_id?: number;
  priority_providers?: Array<Careprovider>;
  product_features?: ProductFeature[];
  seald_encryption_context?: EncryptionContext;
  sender_user_management_file?: SenderUserManagementFile;
  start_date?: number;
  stations?: Array<string>;
  stations_full?: Array<Station>;
  status?: number;
  type?: number;
  updated_at?: number;
};

export type CareseekerPatientInfo = {
  careseeker_patient_info: {
    careseeker: Careseeker;
    has_patients: boolean;
  }[];
};
export type BankingDetails = {
  bank_name?: string;
  iban?: string;
  ik_number?: string;
};

export type FinanceOffice = {
  address?: string;
  city?: string;
  country?: string;
  name?: string;
  zipcode?: string;
};

export type Organization = {
  careproviders?: Array<Careprovider>;
  careseekers?: Array<Careseeker>;
  id: number;
  name: string;
};

export type SocialWorker = Account;

export type Location = {
  city?: string;
  country?: string;
  encrypted_house_number?: EncryptedField | null;
  federal_state?: string;
  floor?: string;
  latitude?: number;
  longitude?: number;
  radius_in_meter?: number;
  street?: string;
  zipcode?: string;
};

export type GrantStatus = (typeof GRANT_STATUSES)[number];
export type ShortTermGrantStatus = (typeof SHORT_TERM_GRANT_STATUSES)[number];

export type PatientCommunication = {
  guardian_contact_information?: EncryptedField | null;
  guardian_description?: string;
  guardian_requested?: boolean;
  has_guardian?: boolean;
  patient_cannot_communicate_in_german?: boolean;
  patient_is_contact?: boolean;
  patient_is_contact_description?: EncryptedField | null;
  patient_language?: string;
  patient_uses_sign_language?: string;
  relatives_available?: boolean;
  relatives_description?: EncryptedField | null;
};

export type Financing = {
  carelevel?: CareLevelInfos | undefined;
  insurance?: Insurance | undefined;
  insurance_id?: number | undefined;
  insurance_number?: EncryptedField | null;
  more_information?: string | undefined;
  patient_has_health_insurance_state?: number;
  payout?: number | undefined;
  preventative_care?: GrantStatus | undefined;
  private_payment_bool?: boolean | undefined;
  public_care_insurance_status?: boolean;
  reimbursment_for_help_at_home?: GrantStatus | undefined;
  reimbursment_secured?: GrantStatus | undefined;
  relief_services?: number;
  short_term_care?: ShortTermGrantStatus | undefined;
  social_help_recipient_bool?: boolean | undefined;
};

export type SelectedPaymentMethod =
  (typeof REHABILITATION_PAYMENT_OPTIONS)[number];

export type Payers = {
  civil_servants_aid?: string | undefined;
  free_from_supplementary_payment_state?: number;
  further_information?: string | undefined;
  insurance?: Insurance | undefined;
  other_payer?: string | undefined;
  public_accident_insurance?: string | undefined;
  public_pension?: Insurance | undefined;
  selected_payment_method?: SelectedPaymentMethod | undefined;
  self_payer?: string | undefined;
  social_service_department?: string | undefined;
  supplementary_insurance?: string | undefined;
  supplementary_insurance_state?: number;
  supplementary_payment?: string | undefined;
  supplementary_payment_state?: number;
};

export type GeneralPractitioner = {
  contact_details?: string | undefined;
  name?: string | undefined;
};

export type LastAction = {
  account?: Pick<
    Account,
    "__typename" | "first_name" | "id" | "last_name"
  > | null;
  timestamp?: string | null;
};

export type PatientProfile = {
  __typename: string;
  admission_date?: number;
  age?: number | null;
  age_interval?: { max: number | null; min: number | null } | null;
  barrier_free?: number;
  barrier_free_description?: string;
  birth_date?: EncryptedField | null;
  care_duration_in_days?: number;
  communication?: PatientCommunication | undefined;
  direct_transfer_necessary?: boolean;
  discharge_date?: number;
  financing?: Financing | undefined;
  first_name?: EncryptedField | null;
  gender?: number;
  general_practitioner?: GeneralPractitioner | undefined;
  has_patient_consent?: boolean;
  height?: EncryptedField | null;
  height_interval?: { min: number } | null;
  interested_long_term_stay?: boolean;
  last_name?: EncryptedField | null;
  lift_available_description?: string | null | undefined;
  lift_available_state?: number | undefined;
  living_situation?: number;
  living_situation_other?: string | undefined;
  preferences?: CarePreferences | undefined;
  unsafe_current_domestic_situation?: string;
  unsafe_current_domestic_situation_state?: number | undefined;
  weight?: EncryptedField | null;
  weight_interval?: { max: number | null; min: number | null } | null;
};

export type DPAFile = {
  content?: string;
  file_name: string;
  file_type: number;
  version?: string;
};

export type SenderUserManagementFile = {
  account_id: number;
  careseeker_id: number;
  file_name: string;
  id: number;
};
export type GetUserManagmentFileParams = Pick<SenderUserManagementFile, "id">;
export type CreateUserManagmentFileParams = Omit<
  SenderUserManagementFile,
  "id"
> & {
  content: string;
};
export type DeleteUserManagmentFileParams = Pick<
  SenderUserManagementFile,
  "id"
>;

/**
 * @deprecated to be replaced with {@link Filev2}
 */
export type Filev1 = Pick<EncryptedContent, "content" | "iv"> & {
  data?: ArrayBuffer;
  encrypted_name?: EncryptedField;
  link?: string;
  name?: string;
  title?: string;
  type?: string;
};

export type FileParams =
  | `auction_id=${number}`
  | `auction_request_id=${number}`;

export type Filev2 = {
  __typename: string;
  account?: Account;
  auction_id: number;
  careprovider?: Careprovider;
  careseeker?: Careseeker;
  category: FileCategory;
  category_other?: string;
  created_at: number;
  file_name?: EncryptedField;
  file_type?: string;
  id: number;
  kis_document_id_string?: FileHISStateful["id"];
  link?: string;
  seald_encrypted_content?: {
    __typename: string;
    created_at?: string;
    file_id?: number;
    id?: number;
    seald_display_id?: string;
    seald_id?: string;
    seald_type?: number;
  };
  seald_encrypted_name?: string;
  seald_encryption_context?: EncryptionContext;
  share_mode: FileShareMode;
  share_options?: { account_id: number; mode: number };
  shared_by: Account;
  shared_with?: FileSharedWith;
  updated_at: number | null;
};

export type Filev2Stateful = Filev2 & { isNewVersionAvailable?: boolean };

export type FileHIS = {
  document_category_number: FileCategory;
  document_category_string: string;
  document_file_name: string;
  document_file_size: number;
  document_id: string;
  from_archive: boolean;
  updated_at: number;
};

export type FileTypeHIS = (typeof ALLOWED_EXTENSIONS_HIS)[number];

export type FileHISStateful = FileHIS & {
  id: FileHIS["document_id"];
  share_mode: FileShareMode;
  type: FileTypeHIS | null;
  unavailable_reason: FileError | null;
};

export type FilesHisParams = {
  externalId: string;
};

export type FileTableActions = typeof FILE_TABLE_ACTIONS;
export type AccountTableActions = typeof ACCOUNT_TABLE_ACTIONS;

export type FileShareMode = (typeof FILE_SHARE_MODE_KINDS)[number];

export type FileSharedWith = {
  __typename: string;
  careseeker_ids?: number[];
  selected_careproviders?: FilesSharedWithSelectedCareprovider[];
};

export type FilesSharedWithSelectedCareprovider = {
  __typename: string;
  account_id?: number;
  careprovider?: Careprovider;
};

export type FileTable =
  | "available"
  | "received"
  | "sent"
  | "unsupported"
  | "uploaded";

export type SelectableCareprovider = {
  id: number;
  name: string;
};

export type CareproviderFile = {
  content?: string;
  file_name: string;
  file_type: number;
  id: number;
  url: string;
};

export type DPAFileType = CareproviderFile & {
  church_owned: boolean;
  version: string;
};

export type CareproviderFiles = {
  documents?: Array<CareproviderFile>;
  images?: Array<CareproviderFile>;
};

export type FileCategory = (typeof FILE_CATEGORIES)[number];
export type FileError = (typeof FILE_ERROR_TYPE)[number];

export type ProviderListing = {
  address?: CareproviderAddress;
  distance?: number;
  facilities?: number[];
  id: number;
  is_consultant?: boolean;
  name?: string;
  profile?: {
    capacity?: {
      capacity_status?: CapacityStatus;
      updated_at?: number | null;
    };
  };
  project_sponsor?: string;
  thumbnail_url?: string;
};

export type ProviderListingDetails = {
  address?: CareproviderAddress;
  central_email?: string;
  central_phone_number?: string;
  distance?: number;
  facilities?: Array<number>;
  fax_number?: string;
  files?: CareproviderFiles;
  id: number;
  name?: string;
  profile?: {
    capacity?: {
      capacity_status?: CapacityStatus;
      updated_at?: number | null;
    };
  };
  services?: Array<number>;
  website?: string;
};

export type EventFilter = {
  no_capacity_expiration?: number;
  no_capacity_reasons?: Array<NoCapacityReason>;
  type: number;
  value?: number;
  values?: Array<number>;
};

export type CapacityUpdate = {
  new_capacity?: Capacity;
  old_capacity?: Capacity;
};

export type EventContext = {
  __typename: string;
  accepted_solutions?: AcceptedSolution[];
  arrival_time?: string;
  author?: string;
  bounced_mails?: {
    __typename?: string; // #TODO: make requried
    bounced_emails: Array<string>;
    total_bounced: number;
    total_sent: number;
  };
  capacity_confirmed?: boolean;
  capacity_date?: number;
  capacity_update?: CapacityUpdate;
  careprovider_name?: string;
  clinic_internal_remarks?: string;
  declined_reason?: number;
  decrypted?: boolean;
  diff?: Array<string>;
  eligibility_reject_reason?: number;
  email?: string;
  file?: Filev1;
  file_type?: number;
  filters?: Array<EventFilter>;
  mail_type?: number;
  manual?: boolean;
  message?: string;
  message_iv?: string;
  messages?: Array<string>;
  new_assignee_name?: string;
  new_roles?: AccountRole[];
  new_status?: number;
  no_capacity_expiration?: number;
  no_capacity_reasons?: Array<NoCapacityReason>;
  old_assignee_name?: string;
  onboarding_comment?: string;
  onboarding_email_type?: number;
  patient_type?: SearchType;
  possible_entry_date?: number;
  previous_status?: number;
  reason?: string;
  recipients?: Array<string>;
  reha_form_status?: number;
  request_link?: string;
  request_unavailable_reason?: number;
  seald_message?: string;
  solution_filter_reasons?: SolutionFilterReasons;
  stop_description?: string;
  stop_reason?: number;
  transferred_to_specialization?: Specialization;
  user_id?: string;
  winning_solution?: number;
};

export type BulkActionType = {
  selectedTotal: number;
  selectedTotalRequests: AuctionRequest[] | null | undefined;
  total: number;
  totalRequests: AuctionRequest[] | null | undefined;
  visibleTotal: number;
  visibleTotalRequests: AuctionRequest[] | null | undefined;
};

export type RequestPage = {
  hash: string;
  ids?: number[];
  // only in direct request tab
  redacted_count?: number;
  remaining: number;
  requests?: Array<AuctionRequest>;
  requests_light?: AuctionRequest[] | null | undefined;
  total: number;
};

export type Page<T> = {
  events: Array<T>;
  hash: string;
  remaining: number;
  total: number;
};

export type LastSeen = {
  account: Account;
  origin: number;
  timestamp?: number;
};

export type Origin = ValueOf<typeof ORIGIN>;
export type EventType = ValueOf<typeof EVENTS>;

export type Event = {
  __typename: string;
  account?: Account;
  careprovider?: Careprovider;
  careseeker?: Careseeker;
  context?: EventContext;
  id?: number;
  last_seens?: LastSeen[];
  origin?: Origin;
  patient_id?: number;
  seen_by_recipient?: boolean;
  seen_by_recipient_timestamp?: number;
  subject_account?: Account;
  timestamp?: number;
  type?: EventType;
  user_id?: string;
};

export type NotificationEvent = Exclude<Event, "user_id"> & {
  auction?: Auction;
  auction_request?: AuctionRequest & { solution?: number };
};

export type EventMessage = Exclude<Event, "last_seens" | "user_id">;

export type RequestEvent = Exclude<Event, "last_seens" | "user_id">;

export type PageView = {
  account?: Account;
  auction_request_id?: number;
  id?: number;
  timestamp?: number;
};

export type RehabForm = {
  medical_form_input?: UserInput;
  request_form_input?: UserInput;
};

export type UserInput = EncryptedContent & {
  type?: string;
  updated_at?: number;
};

export type Station = {
  description?: string;
  id?: number;
  name?: string;
  phone_number?: string;
};

export type EncryptedContent = {
  content: string | null | undefined;
  iv: string | null | undefined;
  seald_content: string | null | undefined;
};

export type EncryptedField = EncryptedContent & {
  decrypted?: string | null | undefined;
};

export type SessionKeyContext = {
  has_session_keys: boolean;
  session_key?: SessionKey;
};

export type Patient = {
  activate_files?: boolean;
  archived?: boolean;
  assignee?: Account;
  auction?: Auction;
  auctions?: Array<Auction>;
  care_needs?: PatientNeeds;
  careseeker?: Careseeker;
  careseeker_id?: number;
  created_at?: number;
  diagnosis?: MedicalDiagnosis;
  drg_code?: string;
  events?: Array<Event>;
  external_id?: string;
  id: number;
  kis_import?: boolean;
  last_action?: LastAction | null;
  profile?: PatientProfile;
  quick_save?: boolean;
  rehab_form?: RehabForm;
  seald_encryption_context?: EncryptionContext;
  session_key_context?: SessionKeyContext;
  session_keys?: Array<SessionKey>;
  social_worker?: Account;
  station?: string;
  station_full?: Station;
  station_full_id?: number;
  transport?: PatientTransport;
  user_id?: string;
  version?: string;
};

export type PatientTransport = {
  accompanying_person?: EncryptedField | null;
  daily_transport_needed?: boolean;
  require_accompanying_person?: boolean;
  transport_organiser?: string;
  transport_type?: number[];
};

type NoCapacityReasonType =
  | typeof NO_CAPACITY_FOR_CARELEVEL
  | typeof NO_CAPACITY_FOR_GENDER
  | typeof NO_CAPACITY_FOR_INFECTION
  | typeof NO_CAPACITY_FOR_ROOM
  | typeof NO_CAPACITY_FOR_SERVICE
  | typeof NO_CAPACITY_FOR_SOLUTION
  | typeof NO_CAPACITY_FOR_SPECIALIZATION;

export type NoCapacityReason = {
  type: NoCapacityReasonType;
  values: number[];
};

export type AuctionResponse = {
  __typename: string;
  accepted_solutions?: AcceptedSolution[];
  arrival_time?: string;
  capacity_date?: number;
  declined_reason?: number;
  message?: string | null;
  message_iv?: string;
  no_capacity_expiration?: number;
  no_capacity_reasons?: Array<NoCapacityReason> | undefined;
  possible_entry_date?: number;
  status?: number;
  updated_at?: number;
};

export type AuctionValidation = {
  auto_validated?: boolean;
  created_at?: number;
  internal_remarks?: string | null;
  message?: string;
  message_iv?: string;
  solution?: number;
  status?: number;
  updated_at?: number;
  validation_reason?: string;
};

export type RequestActionType = (typeof REQUEST_ACTION_TYPES)[number];
export type UndoActionType = (typeof REQUEST_ACTION_UNDO_TYPES)[number];
export type DisabledRequestActionType =
  (typeof REQUEST_CONTEXT_DISABLED_TYPES)[number];

export type UndoRequestAction = {
  action_type: UndoActionType;
  context?: null;
};

export type RequestActionContext = {
  disabled?: DisabledRequestActionType;
};

export type RequestAction = {
  action_type: RequestActionType;
  context?: RequestActionContext;
};

export type RequestActionTypes = RequestAction | UndoRequestAction;

export type RequestActions = RequestActionTypes[];

export type AcceptedSolution = {
  arrival_time?: string;
  possible_entry_date?: number;
  solution: number;
};

type MinimalRoles = Omit<Roles, "careprovider_roles" | "careseeker_roles"> & {
  careprovider_roles?: Array<
    Omit<CareproviderRoles, "careprovider"> & {
      careprovider?: { __typename: string; id: number };
    }
  >;
  careseeker_roles?: Array<
    Omit<CareseekerRoles, "careseeker"> & {
      careseeker?: { __typename: string; id: number };
    }
  >;
};

// careprovider/ careseeker in AuctionRequest account roles reduced to just their ids
type AccountInAuctionRequest = Omit<Account, "roles"> & { roles: MinimalRoles };

export type AuctionRequest = {
  __typename: string;
  access_request_pending?: boolean;
  accounts?: AccountInAuctionRequest[];
  action_required_by_user?: boolean;
  archived?: boolean;
  assignee?: Assignee;
  assignee_id?: number;
  auction?: Auction;
  auction_id?: number;
  careprovider?: Careprovider;
  careprovider_id?: number;
  created_at?: number;
  deleted_at?: number;
  distance?: number;
  events?: Event[];
  fax_sent?: boolean;
  files_count?: number;
  has_capacity?: boolean;
  has_seald_encryption_context?: boolean;
  has_session_keys?: boolean;
  id: number;
  is_provider_search_request?: boolean;
  is_recommendation?: boolean;
  is_reverse_search_match?: boolean;
  last_action?: LastAction;
  last_event?: Event;
  last_seens?: Array<PageView>;
  manually_added?: boolean;
  messages_count?: number;
  messenger_available?: boolean;
  old_auction?: Auction;
  patient_preferred?: boolean;
  patient_preferred_reason?: string;
  preferred?: boolean;
  request_actions?: RequestActions;
  response?: AuctionResponse | null;
  seald_encryption_context?: EncryptionContext;
  seen_by_user?: boolean;
  send_fax?: boolean;
  session_key?: SessionKey;
  session_key_context?: SessionKeyContext;
  solutions?: number[];
  status?: number;
  token?: string;
  updated_at?: number;
  validation?: AuctionValidation;
};

export type CareLevel = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type CareLevelInfos = {
  expedited_request?: boolean;
  has_applied?: boolean | undefined;
  higher_level?: boolean;
  level: CareLevel | undefined;
};

export type MentalHealth = {
  aggressivity?: boolean;
  anxiety?: boolean;
  closed_station?: boolean;
  communication?: number;
  dangerous?: boolean;
  dementia_description?: string | undefined;
  depression?: boolean;
  fixation?: boolean;
  mental_issues?: string | undefined;
  motivation?: number;
  night_watch_description?: string | undefined;
  orientation?: number;
  run_away_description?: string | undefined;
  vigilance?: number;
};

export type Palliative = {
  description: string | undefined;
  palliative_certificate_needed?: boolean;
};

export type IcdCode = {
  code: string;
  description: string;
};

export type GetOntologyType = ({
  key,
  type,
  useShortValues,
}: {
  key: number | null | undefined;
  type: OntologyType;
  useShortValues?: boolean | undefined;
}) => string;

export type GetOntologiesType = ({
  sort,
  type,
}: {
  sort?: boolean | undefined;
  type: OntologyType;
}) => {
  name: string;
  value: number;
}[];

export type CoronaTest = {
  corona?: {
    description?: string | null | undefined;
    test_result?: number | null | undefined;
    vaccinated?: string | null | undefined;
  };
  corona_state?: number | null | undefined;
};

export type HL7Diagnosis = {
  code?: string;
  description?: string;
  type?: string;
};

export type MedicalDiagnosis = {
  addictions?: string;
  additional_examinations_necessary?: string | undefined;
  additional_icd_codes?: Array<IcdCode>;
  allergies?: string | undefined;
  chronic_pains?: string | undefined;
  corona?: CoronaTest["corona"];
  corona_state?: CoronaTest["corona_state"];
  dependency_diagnosis?: DependencyDiagnosis;
  dysphagia?: string | undefined;

  // Only used for analytics purposes for now.
  hl7_diagnoses?: HL7Diagnosis[] | undefined;
  hl7_main_diagnoses?: HL7Diagnosis[] | undefined;
  hl7_secondary_diagnoses?: HL7Diagnosis[] | undefined;

  hospital_stay?: HospitalStay;
  icd_code?: IcdCode;
  infection_and_germs?: InfectionAndGerms;
  infection_and_germs_state?: number;
  limited_capacity?: LimitedCapacity;
  main_diagnosis?: string | undefined;
  medical_history?: string | undefined;
  mental_health?: MentalHealth;
  multimorbidity?: boolean | undefined;
  palliative?: Palliative;
  physical_disabilities?: PhysicalDisabilities;
  rehabilitation_goals?: string;
  rehabilitation_potential?: RehabilitationPotential;
  secondary_diagnosis?: string | undefined;
};

export type BackgroundColorConfig = {
  accent?: boolean;
  black?: boolean;
  dark?: boolean;
  disabled?: boolean;
  error?: boolean;
  primary?: boolean;
  success?: boolean;
  white?: boolean;
};

export type GoToRequest = (
  requestId: number,
  patientId: number,
  auctionId: number,
) => void;

export type Video = {
  activity: string;
  url: string;
};

export type SolutionCount = {
  count: number;
  solution: Solution;
};

export type SolutionCounts = Array<SolutionCount>;

export type Candidates = {
  count: number;
  services?: Array<number>;
};

export type RCryptoKey = {
  account_id: number;
  created_at?: number;
  id?: number;
  private_iv: string;
  private_key_fiftyk?: string;
  private_key_fivek?: string;
  private_key_twenty_fivek?: string;
  public_key: string;
  salt: string;
  updated_at?: number;
};

export type SessionKey = {
  account_id: number;
  algorithm: string | null;
  auction_request_id: number | undefined;
  careprovider_id: number | undefined;
  careseeker_id?: number | undefined;
  id?: number;
  patient_id: number | undefined;
  session_key: string;
  updated_at: number;
};

export type PendingSessionKey = {
  account_id: number;
  account_public_key: string;
  algorithm: string | null;
  auction_request_id: number | undefined;
  careprovider_id: number | undefined;
  careseeker_id: number | undefined;
  created_at: number;
  patient_id: number | undefined;
  user_session_key: string;
};

export type FhirAddress<T = EncryptedField | string> = {
  _line?: { extension: { valueString: T }[] }[];
  houseNumber?: T;
  line?: string[];
}[];

export type FhirEntry<T = EncryptedField | string> = {
  resource?: AnyObject & {
    address?: FhirAddress<T>;
    birthDate?: T;
    name?: { family?: T; given?: T | T[] }[];
    resourceType?: string;
    valueQuantity?: { value?: T };
  };
};

type FhirPatientType<T = EncryptedField | string> = {
  entry?: Array<FhirEntry<T>>;
  id?: string;
  resourceType?: string;
  timestamp?: string;
  type?: string;
};

export type EncryptedFhirPatient = FhirPatientType<EncryptedField>;
export type DecryptedFhirPatient = FhirPatientType<string>;

export type EncryptionJob = {
  auction_id?: number;
  fhir_patient?: EncryptedFhirPatient;
  id: number;
  patient_encrypted_session_key?: SessionKey;
  patient_seald_encryption_context?: EncryptionContext;
  receiver_integration_public_key?: string;
};

export type ReportBasicInformation = {
  ClinicName?: string;
  EndDate?: number;
  GenerationDate?: number;
  StartDate?: number;
};

export type ByStopReason = {
  Count: number;
  StopReason: number;
};

export type BySolution = {
  Count: number;
  RelevantSearches: number;
  Solutions: Array<number>;
  StartedSearches: number;
};

export type ByStatus = {
  Count: number;
  Status: number;
};

export type ByProviderSolution = {
  CareproviderName: string;
  Count: number;
  Solution: number;
};

export type TimeMetricBySolution = {
  Solution: number;
  TimeToAttribution: number;
  TimeToFirstPositiveResponse: number;
};

export type ProviderForSolution = {
  ActiveProviders: number;
  SolutionValue: number;
};

export type ClinicReport = {
  BasicInformation?: ReportBasicInformation;
  CancelReasonBreakdown?: Array<ByStopReason>;
  ProviderSolutionBreakdown?: Array<ByProviderSolution>;
  ProvidersPerSolution?: Array<ProviderForSolution>;
  SolutionBreakdown?: Array<BySolution>;
  StatusBreakdown?: Array<ByStatus>;
  StopDescriptions?: Array<string>;
  TimeMetrics?: Array<TimeMetricBySolution>;
};

export type Interval = {
  max?: number;
  min?: number;
};

export type CareproviderOnboarding = {
  call_back_date?: number;
  comment?: string;
  facility_size?: number;
  information_system?: number;
  information_system_other?: string;
  new_facility_openings?: number;
  responsible_person?: number;
  status: number;
  waiting_list_usage?: number;
};

export type RelatedCareseeker = {
  careseeker?: Careseeker;
  distance: number;
  prioritized: boolean;
};

export type Env = "demo" | "development" | "preprod" | "production" | "staging";

export type EnvContext = {
  app: AppType;
  config: ConfigType | null;
  env: Env;
  setEnv: (env: Env, newPrName?: string) => void;
};

type AlgoliaApiKey = {
  apiKey: string;
  appId: string;
};
type AlgoliaApiKeysByApp = Record<AppType, string>;
export type AlgoliaApiKeysByCountry = Record<Country, AlgoliaApiKeysByApp>;

export type ConfigType = {
  algolia: AlgoliaApiKey;
  backend: {
    host?: string;
    port?: number;
    protocol?: string;
    url?: string;
  };
  environment: Env;
  intercom: {
    faqUrl: { [key: string]: string };
    key: string;
  };
  mapbox: string;
  seald: {
    apiUrl: string;
    appId: string;
    ssksUrl: string;
  };
  skippedVersion?: number;
  version?: string;
};

export type Recommendation = {
  auction?: Auction;
  auction_id?: number;
  careprovider_id?: number;
  id: number;
  seen_by_user?: boolean;
  solutions?: number[];
};

export type Recommendations = {
  hash?: string;
  remaining?: number;
  requests?: Array<Recommendation>;
  total?: number;
};

export type DispatchAction = { payload: AnyObject; type: string };

export type Dispatch = (action: DispatchAction) => void;

export type Notification = {
  Component?: React.ComponentType<{ nextNotification: () => void }>;
  a11yType?: A11yStatusUpdate["role"];
  key?: string;
  message: string;
  type?: NotifyVariant;
};

export type NotifyVariant = "error" | "success" | undefined;

export type Notify = (options: Notification) => void;

export type BarthelIndex = {
  date: number;
  deleted?: boolean;
  form: {
    bathing: number | null | undefined;
    dressing_and_undressing: number | null | undefined;
    fecal_incontinence: number | null | undefined;
    food: number | null | undefined;
    getting_up_and_walking: number | null | undefined;
    personal_care: number | null | undefined;
    setting_up_and_relocating: number | null | undefined;
    stair_climbing: number | null | undefined;
    toilet_use: number | null | undefined;
    urinary_incontinence: number | null | undefined;
  };
};

export type RehabIndex = {
  date: number;
  form: {
    behavioural_disorder: number | null | undefined;
    intensive_medical_monitoring: number | null | undefined;
    intermittent_ventilation: number | null | undefined;
    orientation_disorder: number | null | undefined;
    severe_communication_disorder: number | null | undefined;
    supervised_swallowing_disorder: number | null | undefined;
    tracheostoma_requiring_aspiration: number | null | undefined;
  };
};

export type PatientMessage = { content?: string; file?: Filev1; iv?: string };

export type PatientWhitelistDefinition = PatientWhitelistScope[];

export type PatientWhitelistProps = {
  careseeker?: Careseeker | null;
  formInputValue: Auction | null | undefined;
};

export type PatientWhitelistHookProps<K extends keyof PatientWhitelist> = {
  auction: Auction | undefined;
  formConfigurationOverride?: Array<string>;
  slug: K;
};

export type PatientInfoWhitelist = {
  accompanying_person: PatientWhitelistDefinition;
  age_interval: PatientWhitelistDefinition;
  barrier_free_room: PatientWhitelistDefinition;
  birth_date: PatientWhitelistDefinition;
  carelevel: PatientWhitelistDefinition;
  civil_servants_aid: PatientWhitelistDefinition;
  decisions_and_language: PatientWhitelistDefinition;
  encrypted_house_number: PatientWhitelistDefinition;
  expedited_request: PatientWhitelistDefinition;
  financing: PatientWhitelistDefinition;
  financing_insurance: PatientWhitelistDefinition;
  first_name: PatientWhitelistDefinition;
  free_from_supplementary_payment_state: PatientWhitelistDefinition;
  further_information: PatientWhitelistDefinition;
  gender: PatientWhitelistDefinition;
  general_practitioner: PatientWhitelistDefinition;
  general_practitioner_contact_details: PatientWhitelistDefinition;
  general_practitioner_name: PatientWhitelistDefinition;
  guardian_contact_information: PatientWhitelistDefinition;
  guardian_description: PatientWhitelistDefinition;
  guardian_requested: PatientWhitelistDefinition;
  has_applied: PatientWhitelistDefinition;
  has_guardian: PatientWhitelistDefinition;
  height: PatientWhitelistDefinition;
  higher_level: PatientWhitelistDefinition;
  insurance_id: PatientWhitelistDefinition;
  insurance_number: PatientWhitelistDefinition;
  last_name: PatientWhitelistDefinition;
  level: PatientWhitelistDefinition;
  lift_available_description: PatientWhitelistDefinition;
  lift_available_state: PatientWhitelistDefinition;
  living_situation: PatientWhitelistDefinition;
  living_situation_other: PatientWhitelistDefinition;
  location: PatientWhitelistDefinition;
  more_information: PatientWhitelistDefinition;
  other_payer: PatientWhitelistDefinition;
  patient_cannot_communicate_in_german: PatientWhitelistDefinition;
  patient_is_contact: PatientWhitelistDefinition;
  patient_is_contact_description: PatientWhitelistDefinition;
  patient_language: PatientWhitelistDefinition;
  patient_uses_sign_language: PatientWhitelistDefinition;
  payers: PatientWhitelistDefinition;
  payers_insurance: PatientWhitelistDefinition;
  payout: PatientWhitelistDefinition;
  preferences: PatientWhitelistDefinition;
  preventative_care: PatientWhitelistDefinition;
  private_payment_bool: PatientWhitelistDefinition;
  public_accident_insurance: PatientWhitelistDefinition;
  public_care_insurance_status: PatientWhitelistDefinition;
  public_pension: PatientWhitelistDefinition;
  radius_in_km: PatientWhitelistDefinition;
  reimbursment_for_help_at_home: PatientWhitelistDefinition;
  reimbursment_secured: PatientWhitelistDefinition;
  relatives_available: PatientWhitelistDefinition;
  relatives_description: PatientWhitelistDefinition;
  relief_services: PatientWhitelistDefinition;
  room_type: PatientWhitelistDefinition;
  selected_payment_method: PatientWhitelistDefinition;
  self_payer: PatientWhitelistDefinition;
  short_term_care: PatientWhitelistDefinition;
  social_help_recipient_bool: PatientWhitelistDefinition;
  social_service_department: PatientWhitelistDefinition;
  specialist_doctor: PatientWhitelistDefinition;
  supplementary_insurance: PatientWhitelistDefinition;
  supplementary_insurance_state: PatientWhitelistDefinition;
  supplementary_payment: PatientWhitelistDefinition;
  supplementary_payment_state: PatientWhitelistDefinition;
  unsafe_current_domestic_situation: PatientWhitelistDefinition;
  unsafe_current_domestic_situation_state: PatientWhitelistDefinition;
  weight: PatientWhitelistDefinition;
};

export type CSSPosition =
  | "-moz-initial"
  | "-webkit-sticky"
  | "absolute"
  | "fixed"
  | "inherit"
  | "initial"
  | "relative"
  | "revert"
  | "static"
  | "sticky"
  | "unset"
  | undefined;

export type CSSTextAlign =
  | "-moz-initial"
  | "center"
  | "end"
  | "inherit"
  | "initial"
  | "justify"
  | "left"
  | "match-parent"
  | "revert"
  | "right"
  | "start"
  | "unset"
  | undefined;

export type PatientMedicalWhitelist = {
  addictions: PatientWhitelistDefinition;
  additional_icd_codes: PatientWhitelistDefinition;
  additional_specializations: PatientWhitelistDefinition;
  allergies: PatientWhitelistDefinition;
  barthel_index: PatientWhitelistDefinition;
  barthel_index_list: PatientWhitelistDefinition;
  chronic_pains: PatientWhitelistDefinition;
  clostridien: PatientWhitelistDefinition;
  corona: PatientWhitelistDefinition;
  corona_state: PatientWhitelistDefinition;
  dependency_level: PatientWhitelistDefinition;
  doctor_in_charge_in_hospital: PatientWhitelistDefinition;
  doctor_in_charge_in_hospital_phone: PatientWhitelistDefinition;
  dysphagia: PatientWhitelistDefinition;
  early_rehab_barthel_index: PatientWhitelistDefinition;
  early_rehab_barthel_index_list: PatientWhitelistDefinition;
  evaluation: PatientWhitelistDefinition;
  four_mrgn: PatientWhitelistDefinition;
  geriatric_early_stage: PatientWhitelistDefinition;
  help_at_home: PatientWhitelistDefinition;
  hospital_stay: PatientWhitelistDefinition;
  icd_code: PatientWhitelistDefinition;
  icd_codes: PatientWhitelistDefinition;
  infection_and_germs: PatientWhitelistDefinition;
  infection_and_germs_state: PatientWhitelistDefinition;
  limited_capacity: PatientWhitelistDefinition;
  limited_capacity_other: PatientWhitelistDefinition;
  limited_cognitive_capacity: PatientWhitelistDefinition;
  limited_physical_capacity: PatientWhitelistDefinition;
  limited_psychological_capacity: PatientWhitelistDefinition;
  limited_social_behaviour: PatientWhitelistDefinition;
  main_diagnosis: PatientWhitelistDefinition;
  mrsa: PatientWhitelistDefinition;
  multimorbidity: PatientWhitelistDefinition;
  operation_date: PatientWhitelistDefinition;
  operation_description: PatientWhitelistDefinition;
  other: PatientWhitelistDefinition;
  rehabilitation_goals: PatientWhitelistDefinition;
  rehabilitation_potential: PatientWhitelistDefinition;
  rehabilitation_potential_description: PatientWhitelistDefinition;
  remarks: PatientWhitelistDefinition;
  requires_isolation: PatientWhitelistDefinition;
  secondary_diagnosis: PatientWhitelistDefinition;
  three_mrgn: PatientWhitelistDefinition;
  vre: PatientWhitelistDefinition;
};

export type PatientTransportWhitelist = {
  accompanying_person: PatientWhitelistDefinition;
  daily_transport_needed: PatientWhitelistDefinition;
  require_accompanying_person: PatientWhitelistDefinition;
  transport_organiser: PatientWhitelistDefinition;
  transport_type: PatientWhitelistDefinition;
};

export type PatientMobilityWhitelist = {
  assistive_equipment: PatientWhitelistDefinition;
  auditive_impairment: PatientWhitelistDefinition;
  existing_medical_supplies: PatientWhitelistDefinition;
  mobility: PatientWhitelistDefinition;
  mobility_additional_information: PatientWhitelistDefinition;
  orthese: PatientWhitelistDefinition;
  prescribed_medical_supplies: PatientWhitelistDefinition;
  prothese: PatientWhitelistDefinition;
  required_medical_supplies: PatientWhitelistDefinition;
  visual_impairment: PatientWhitelistDefinition;
};

export type PatientNeedsWhitelist = {
  access_drainages: PatientWhitelistDefinition;
  base_care: PatientWhitelistDefinition;
  base_care_companionship_grant: PatientWhitelistDefinition;
  base_care_description: PatientWhitelistDefinition;
  body_care: PatientWhitelistDefinition;
  breathing: PatientWhitelistDefinition;
  central_venous_catheter: PatientWhitelistDefinition;
  circulation: PatientWhitelistDefinition;
  companionship: PatientWhitelistDefinition;
  companionship_description: PatientWhitelistDefinition;
  companionship_grant: PatientWhitelistDefinition;
  compression: PatientWhitelistDefinition;
  compression_description: PatientWhitelistDefinition;
  dialysis: PatientWhitelistDefinition;
  drainage: PatientWhitelistDefinition;
  dressing: PatientWhitelistDefinition;
  dysphagia: PatientWhitelistDefinition;
  eating: PatientWhitelistDefinition;
  enteral_nutrition: PatientWhitelistDefinition;
  fecal_incontinence: PatientWhitelistDefinition;
  gastric_tube: PatientWhitelistDefinition;
  help_at_home: PatientWhitelistDefinition;
  help_at_home_description: PatientWhitelistDefinition;
  hemodialysis: PatientWhitelistDefinition;
  incontinence: PatientWhitelistDefinition;
  injection: PatientWhitelistDefinition;
  injection_description: PatientWhitelistDefinition;
  medication: PatientWhitelistDefinition;
  medication_description: PatientWhitelistDefinition;
  metabolism: PatientWhitelistDefinition;
  monitoring: PatientWhitelistDefinition;
  nutrition: PatientWhitelistDefinition;
  oxygen_therapy: PatientWhitelistDefinition;
  palliative: PatientWhitelistDefinition;
  palliative_certificate_needed: PatientWhitelistDefinition;
  palliative_description: PatientWhitelistDefinition;
  parenteral_nutrition: PatientWhitelistDefinition;
  peripheral_venous_catheter: PatientWhitelistDefinition;
  peritoneal_dialysis: PatientWhitelistDefinition;
  permanent_catheter: PatientWhitelistDefinition;
  psychiatric_care: PatientWhitelistDefinition;
  psychiatric_care_description: PatientWhitelistDefinition;
  remarks: PatientWhitelistDefinition;
  respiration: PatientWhitelistDefinition;
  specialised_intensive_nurse: PatientWhitelistDefinition;
  support: PatientWhitelistDefinition;
  suprapubic_catheter: PatientWhitelistDefinition;
  tracheal_cannula: PatientWhitelistDefinition;
  tracheostomy: PatientWhitelistDefinition;
  urinary_catheter: PatientWhitelistDefinition;
  urinary_incontinence: PatientWhitelistDefinition;
  wound_care: PatientWhitelistDefinition;
  wound_care_description: PatientWhitelistDefinition;
};

export type PatientMentalWhitelist = {
  aggressivity: PatientWhitelistDefinition;
  anxiety: PatientWhitelistDefinition;
  closed_station: PatientWhitelistDefinition;
  communication: PatientWhitelistDefinition;
  dangerous: PatientWhitelistDefinition;
  dementia_description: PatientWhitelistDefinition;
  depression: PatientWhitelistDefinition;
  fixation: PatientWhitelistDefinition;
  mental_issues: PatientWhitelistDefinition;
  mental_state_fields: PatientWhitelistDefinition;
  motivation: PatientWhitelistDefinition;
  night_watch_description: PatientWhitelistDefinition;
  orientation: PatientWhitelistDefinition;
  run_away_description: PatientWhitelistDefinition;
  vigilance: PatientWhitelistDefinition;
};

export type GenericWhitelist = Record<string, PatientWhitelistDefinition>;

export type PatientTransferWhitelist = GenericWhitelist;

export type PrecomputePatientWhitelist = {
  [ASSESSMENT_SLUG_ELECTIVE_REHAB_INFO]: GenericWhitelist;
  [ASSESSMENT_SLUG_ELECTIVE_REHAB_MEDICAL]: GenericWhitelist;
  [ASSESSMENT_SLUG_HOME_CARE_INFO]: GenericWhitelist;
  [ASSESSMENT_SLUG_HOME_CARE_SEARCH]: GenericWhitelist;
  [ASSESSMENT_SLUG_MEDICAL_SUPPLIES_CATALOGUE]: GenericWhitelist;
  [ASSESSMENT_SLUG_MEDICAL_SUPPLIES_INFO]: GenericWhitelist;
  [ASSESSMENT_SLUG_MOBILE_SHORT]: GenericWhitelist;
  [ASSESSMENT_SLUG_SHORT_REHAB_INFO]: GenericWhitelist;
  [ASSESSMENT_SLUG_SHORT_REHAB_MEDICAL]: GenericWhitelist;
  [ASSESSMENT_SLUG_TRANSPORT_INFO]: GenericWhitelist;
  [ASSESSMENT_SLUG_TRANSPORT_SEARCH]: GenericWhitelist;
  info: PatientInfoWhitelist;
  medical: PatientMedicalWhitelist;
  mental: PatientMentalWhitelist;
  mobility: PatientMobilityWhitelist;
  needs: PatientNeedsWhitelist;
  transfer: PatientTransferWhitelist;
  transport: PatientTransportWhitelist;
};

export type StepsType = keyof PrecomputePatientWhitelist;

export type PatientWhitelist = {
  [stepKey in keyof PrecomputePatientWhitelist]?: {
    [valueKey in keyof PrecomputePatientWhitelist[stepKey]]: boolean;
  };
};

export type PatientWhitelistAssessmentStep = {
  [stepKey in keyof PrecomputePatientWhitelist]: PatientWhitelistDefinition;
};

export type PatientWhitelistScope = {
  country?: "DE" | "FR";
  search_type?: SearchType;
  sender_type?: number;
  solutions?: Array<number>;
  specializations?: Array<number>;
  variant?: AssessmentVariantType;
};

export type IsSealdOnly = (sessionsInContext?: {
  newSealdSession: unknown;
  oldSession: unknown;
}) => boolean;

export type ApolloEncryptionContext = {
  decrypt: boolean;
  isSealdOnly: IsSealdOnly;
  privateKey: string | undefined;
  trackEvent: TrackEventFn;
};

export type ScopeType = {
  country?: "DE" | "FR";
  patient_type?: SearchType;
  search_type?: SearchType;
  sender_type?: number;
  solutions?: Array<number>;
  variant?: string;
};

export type FormConfigType = {
  form_name: string;
  position: number;
  scopes: Array<ScopeType>;
};

export type FieldConfiguration = {
  param_name: string;
  position: number;
  scopes?: Array<ScopeType>;
};

export type FormConfigurationScope = PatientWhitelistScope;

export type FormConfiguration = {
  form_name: string;
  param_name: string;
  position: number;
  scopes: FormConfigurationScope[];
};

export type FormConfigurationField = {
  field_name: string;
  id: number;
  path: string;
};

export type FaxResponse = {
  url: string;
};

export type AddressCircle = {
  city: string;
  coordinates: { latitude: number; longitude: number; zipcode: string };
  country: string;
  floor?: number;
  house_number: string;
  radius: number;
  street: string;
  street_number: string;
  zipcode: string;
};

export type SidebarMenuItem = {
  action: AnyFunction;
  deactivated?: boolean;
  divider?: boolean;
  icon: any;
  key: string;
  label: string;
  showBadge?: boolean;
  showNotification?: boolean;
};

export type SenderReceiverNote = {
  note: string;
};

export type ScrollPosition = {
  x: number | undefined;
  y: number | undefined;
};

export type TooltipPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top"
  | undefined;

export type MedicalProductType = {
  description: string;
  id: string;
  location: number;
  name: string;
  objectID?: string;
  product_group: number;
  subgroup: number;
  type: number;
};

export type MedicalSupplies = {
  additional_information?: string;
  delivery_address?: Location;
  delivery_address_type?: number;
  prescription_issued?: boolean;
  prescription_issued_description?: string;
  products?: MedicalProductType[];
};

export type ProviderBlacklistingRule = {
  careprovider_id?: number;
  careseeker_id?: number;
  created_at?: number;
  filter_type: number;
  id?: number;
  is_whitelist?: boolean;
  product_group?: number;
  search_partial_zipcode?: string;
  specialization?: number;
};

export type ProviderBlackListing = {
  rules: ProviderBlacklistingRule[];
  scope: {
    careprovider_id: number;
    filter_type: number;
  };
};

export type RequestHeaderContextType = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  patientId: string | undefined;
  status: number | undefined;
  updateRequestHeader: React.Dispatch<
    React.SetStateAction<RequestHeaderContextType>
  >;
};

export type CareproviderRequests = {
  remaining: number | null;
  requests: any[];
  total: number | null;
};

export type EncryptionHint = "secure" | "unsecure";

export type PostMessageAction =
  | "decrypt_events"
  | "decrypt_file"
  | "decrypt_request"
  | "decrypt_requests"
  | "encrypt_message"
  | "generate_request_session_keys"
  | "login";

export type EncryptionJobMaterial = {
  encryption_public_key: string;
  signing_private_key?: string;
};

export type EncryptionJobRequest = {
  aes_encrypted_data: string;
  compressed: boolean;
  data_signature: string | undefined;
  encrypted_aes_secrets: string;
};

export type DataDogLogEntry = {
  level: "error" | "info" | "warn";
  message: string;
  tags?: { [key: string]: number | string };
};

export type DashboardRequestCount = {
  archived: number | undefined;
  attributed: number | undefined;
  pending: number | undefined;
};

export type CareproviderDpaFile = {
  church_owned: boolean;
  file_name?: string;
  signed_at?: number;
  uploaded_at?: number;
};

export type CareproviderFileDirectoryKinds = "dpa" | "files";

export type MessengerTabKinds = (typeof ALL_MESSENGER_TABS)[number];

export type OnRefreshSearchPanel = { should_refresh?: boolean };
export type OnRefreshSearchMerge = { full_page_refresh?: boolean };

export type SealdIdentityInput = {
  account_id: number;
  datetime: string;
};

export type SealdIdentityPayload = {
  must_authenticate: boolean;
  seald_two_man_rule_key: string;
  seald_two_man_rule_session_id: string;
  seald_user_license_token: string;
};

export type AppType = ValueOf<typeof APP_KIND>;
export type AppName = ValueOf<typeof APP_VERSION_NAMES>;
export type TrackerAppName = AppName | "mock app" | "storybook" | "test";

export type ProviderSearch = (typeof PROVIDER_SEARCHES)[number];
export type ProviderSearchName = (typeof PROVIDER_SEARCH_VERSIONS)[number];

export type ActiveProviderSearch = (typeof ACTIVE_PROVIDER_SEARCHES)[number];
export type ActiveProviderSearchName =
  (typeof ACTIVE_PROVIDER_SEARCH_VERSIONS)[number];

export type Version = {
  __typename: string;
  application: AppName;
  id: number;
  version: string;
};

export type Versions = Version[];

export type BrowserSubscription = PushSubscriptionJSON & {
  id_token?: string | null;
};
export type SEALD_FLOW_TYPES = (typeof SEALD_FLOWS)[number];

export type SealdIdentityCheck = {
  has_2mr: boolean;
  has_password: boolean;
  must_authenticate: boolean;
};

export type CareproviderTab =
  | typeof CAREPROVIDER_TAB_TYPE_DIRECT
  | typeof CAREPROVIDER_TAB_TYPE_ATTRIBUTED
  | typeof CAREPROVIDER_TAB_TYPE_IN_PROGRESS
  | typeof CAREPROVIDER_TAB_TYPE_NEW
  | typeof CAREPROVIDER_TAB_TYPE_OTHER;

export type CareseekerTab =
  | typeof CARESEEKER_TAB_TYPE_ARCHIVED
  | typeof CARESEEKER_TAB_TYPE_DRAFT
  | typeof CARESEEKER_TAB_TYPE_FINISHED
  | typeof CARESEEKER_TAB_TYPE_PATIENTS;

export type GenericDashboardTab = CareproviderTab | CareseekerTab;
export type GenericDashboardTabKey =
  | ValueOf<typeof CAREPROVIDER_DASHBOARD_TAB>
  | ValueOf<typeof CLINIC_TODO_DASHBOARD_TAB>;

export type FilterValueAgeInterval = DeepNullableObj<Interval>;
export type FilterValueCarelevels = number[];
export type FilterValueCareseekers = number[];
export type FilterValueGenders = number[];
export type FilterValueElective = boolean | null;
export type FilterValueSenders = { id: number; label: string; value: string }[];
export type FilterValuePatientTypes = SearchType[];
export type FilterValueSolutions = number[];
export type FilterValueSocialWorkers = number[];
export type FilterValueSpecializations = number[];
export type FilterValueStations = number[];
export type FilterValueTab = string;
export type FilterValueTypename = string;
export type FilterValueStartDateRange = {
  from_date: number | null;
  to_date: number | null;
} | null;
export type FilterValueServices = number[];
export type FilterValueStartDate = number;
export type FilterValueCapacity = boolean;
export type FilterValueTreatmentLocation = {
  id?: any;
  label: string;
  latitude?: number;
  longitude?: number;
  value: string;
};
export type FilterValueDistance = number;
export type FilterValueWithConsultant = boolean;
export type FilterValueWithProvider = boolean;
export type FilterValueIsDirect = boolean;
export type FilterValuePersistState = boolean;
export type FilterValuePaginationStart = number;
export type FilterValuePaginationCount = number;
export type FilterValueWeightInterval = DeepNullableObj<Interval>;
export type FilterValueTransitionalCare = boolean | null;

export type DashboardFilterLocalStorageBaseKey =
  | typeof RECEIVER_DASHBOARD_FILTERS
  | typeof SENDER_DASHBOARD_FILTERS;

export type CapacityStatus = (typeof CAPACITY_STATUSES)[number];

// relates to the BE status of candidates calculation
// which is now asynchronously completed on the tool server
export type CandidatesStatus = (typeof CANDIDATES_STATUS)[number];

export type SearchMergeStopSearchPanel =
  (typeof SEARCH_MERGE_STOP_SEARCH_PANELS)[number];

export type ExperimentAttribution = {
  account_id?: number;
  experiment_id?: number;
  experiment_name?: string;
  experiment_value?: string;
};

export type RequestChipStatus = (typeof REQUEST_CHIP_STATUSES)[number];

export type SearchPanelCandidate = {
  careprovider: {
    __typename: string;
    address?: {
      address?: string;
      city?: string;
      street?: string;
      street_number?: string;
      zip_code?: string;
    };
    id: number;
    inactive_reason?: number;
    name: string;
    profile?: CareproviderProfile;
    status?: number;
  };
  distance: number;
  id: number;
  send_fax?: boolean;
  solutions?: number[];
};

export type SearchPanelRequest = {
  careprovider?: {
    __typename: string;
    address?: {
      address?: string;
      city?: string;
      street?: string;
      street_number?: string;
      zip_code?: string;
    };
    id: number;
    name: string;
  };
  created_at: number;
  distance: number;
  id: number;
  request_actions?: RequestActions;
  solutions?: number[];
};

export type AcceptedTableRequest = SearchPanelRequest & {
  chip_status: RequestChipStatus;
  patient_data_shared: boolean;
  patient_preferred: boolean;
  patient_preferred_reason: string;
  potential_start_date: number;
  potential_start_time: string;
};

export type AcceptedTablePayload = {
  requests?: AcceptedTableRequest[];
  total?: number;
};

export type ContactedTableRequest = SearchPanelRequest & {
  chip_status: RequestChipStatus;
  patient_data_shared: boolean;
  patient_preferred: boolean;
  patient_preferred_reason: string;
  recommendation_id?: number;
};

export type ContactedTablePayload = {
  requests?: ContactedTableRequest[];
  total?: number;
};

export type PotentialReceiversTableCandidate = SearchPanelCandidate;

export type PotentialReceiversTablePayload = {
  requests?: PotentialReceiversTableCandidate[];
  total?: number;
};

export type SolutionFilterReasons = Record<string, EventFilter[]>;

export type FilteredTableFilteredCandidate = SearchPanelCandidate & {
  recommendation_id?: number;
  send_fax?: boolean;
  solution_filter_reasons: SolutionFilterReasons;
};

export type FilteredTablePayload = {
  filtered_candidates?: FilteredTableFilteredCandidate[];
  total?: number;
};

export type RejectedTableRequest = SearchPanelRequest & {
  chip_status: RequestChipStatus;
  patient_preferred: boolean;
  patient_preferred_reason: string;
  validation: {
    rejected_at: number;
  };
};

export type RejectedTablePayload = {
  requests?: RejectedTableRequest[];
  total?: number;
};

export type DeclinedTableRequest = SearchPanelRequest & {
  chip_status: RequestChipStatus;
  patient_preferred: boolean;
  patient_preferred_reason: string;
  response: {
    capacity_date?: number;
    declined_at: number;
    declined_reason?: number;
    message?: string | null;
    no_capacity_expiration?: number;
    no_capacity_reasons?: Array<NoCapacityReason> | undefined;
  };
};

export type DeclinedTablePayload = {
  requests?: DeclinedTableRequest[];
  total?: number;
};

export type ValidatedTableRequest = SearchPanelRequest & {
  chip_status: RequestChipStatus;
  patient_data_shared: boolean;
  patient_preferred: boolean;
  patient_preferred_reason: string;
};

export type ValidatedTablePayload = {
  request?: ValidatedTableRequest;
  total?: undefined;
};

export type SearchTable = (typeof SEARCH_TABLE_TABLES)[number];
export type TableCount = (typeof SEARCH_TABLE_ROW_COUNTS)[number];
export type TableSortValue = keyof typeof SORTING_MAP;
export type ActiveSearchMergeStatus =
  (typeof ACTIVE_SEARCH_MERGE_STATUSES)[number];
export type SearchMergeStatus = (typeof SEARCH_MERGE_STATUSES)[number];

export type AuctionStepperStepNumber = (typeof AUCTION_STEPPER_STEPS)[number];

export type TablePageState = {
  count: TableCount;
  page: number;
  sortBy: TableSortValue;
};

export type SearchTablePagination = {
  [index in SearchTable]: TablePageState;
};

export type TablePayload = (typeof TABLES_PAYLOAD)[number];

export type SearchMergePayload = {
  [TABLE_PAYLOAD_ACCEPTED]: AcceptedTablePayload;
  [TABLE_PAYLOAD_CONTACTED]: ContactedTablePayload;
  [TABLE_PAYLOAD_DECLINED]: DeclinedTablePayload;
  [TABLE_PAYLOAD_FILTERED]: FilteredTablePayload;
  [TABLE_PAYLOAD_POTENTIAL]: PotentialReceiversTablePayload;
  [TABLE_PAYLOAD_REJECTED]: RejectedTablePayload;
  [TABLE_PAYLOAD_VALIDATED]: ValidatedTablePayload;
  context: {
    search_merge_banners: SearchMergeBanner[];
    search_merge_status: SearchMergeStatus | undefined;
  };
};

export type SearchTableTotals = { [key in TablePayload]?: number };

export type SearchMergeTableRequest =
  | AcceptedTableRequest
  | ContactedTableRequest
  | DeclinedTableRequest
  | FilteredTableFilteredCandidate
  | PotentialReceiversTableCandidate
  | RejectedTableRequest;

export type CyFeatureFlagKey =
  | "activateSelectableProviders"
  | "activateWebSocket";

export type CyFeatureFlags = Partial<Record<CyFeatureFlagKey, boolean>>;

export type ProductTourCompletion = {
  completed: boolean | null;
  product_tour_id: number;
};

export type RadioOption = {
  disabled?: boolean;
  id: number | string;
  label: string;
  subForm?: React.ReactNode;
  value: number;
};

export type IndexDbSessionKeysDB = typeof INDEX_DB_SESSION_KEYS;
export type IndexDbSessionKeysDBVersion = typeof INDEX_DB_SESSION_KEYS_VERSION;
export type IndexDbSessionKeysTableName = (typeof INDEX_DB_TABLES)[number];

export type AssessmentSlug = (typeof ASSESSMENT_SLUGS)[number];

export type MUIPickersDate = Date | null;

export type WebSocketStatus = keyof typeof WEBSOCKET_STATUS;

export type SearchType = (typeof SEARCH_TYPES)[number];

export type SearchMergeBanner = (typeof SEARCH_MERGE_BANNERS)[number];

export type OnpremPatient = {
  admit_date_time?: number;
  allergies?: string;
  anamnesis?: string;
  attending_doctor?: OnpremDoctor;
  barthel_index?: string;
  birth_date?: string;
  care_level?: string;
  consulting_doctor?: OnpremDoctor;
  discharge_date_time?: number;
  evolution?: string;
  external_id?: string;
  first_name?: string;
  gender?: number;
  guardians?: Array<OnpremNextOfKin>;
  height?: string;
  hospital_id?: string;
  infections?: string;
  insurance?: {
    insurance_company_name?: string;
    insurance_id_number?: string;
    insurance_ik_number?: string;
  };
  last_name?: string;
  location?: {
    city: string;
    postal_code: string;
    street_address: string;
  };
  main_diagnoses?: Array<OnpremDiagnosis>;
  medical_history?: string;
  medical_instructions?: string;
  patient_contact?: PatientIsContact;
  personal_id?: string; // not implemented
  recommended_routine?: string;
  relatives?: Array<OnpremNextOfKin>;
  secondary_diagnoses?: Array<OnpremDiagnosis>;
  station?: string;
  weight?: string;
};

export type PatientIsContact = {
  email: string;
  home_address: {
    city?: string;
    postal_code?: string;
    street_address?: string;
  };
  phone_number: string;
};

export type OnpremDoctor = {
  address_city?: string;
  address_postal_code?: string;
  address_street?: string;
  degree?: string | null;
  discipline?: string;
  doctorType?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  phone_prefix?: string;
  prefix?: string;
};

export type OnpremDiagnosis = {
  diagnosis_code?: string;
  diagnosis_codesystem_version?: string;
  diagnosis_description?: string;
  diagnosis_id?: number;
  diagnosis_type?: string;
};

export type OnpremNextOfKin = {
  address_city?: string;
  address_postal_code?: string;
  address_street?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  relationship?: string;
};

export type AlgoliaAnalyticsName = (typeof ALGOLIA_ANALYTICS_NAMES)[number];
export type AlgoliaIndex = ValueOf<typeof ALGOLIA_INDICES>;

export type SealdSessionAccess = {
  id: number | null;
  name: string | null;
  pending_access_created_at: number | null;
  seald_access: boolean | null;
  seald_db_access_created_at: number | null;
};

export type SealdSessionsPayload = {
  careprovider_accesses: Array<SealdSessionAccess> | null;
  careseeker_accesses: Array<SealdSessionAccess> | null;
  seald_encryption_context_created_at: number | null;
};

export type ProviderSearchAuction = {
  id?: number;
  profile?: Pick<AuctionProfile, "search_location">;
  solutions: Auction["solutions"];
  start_date: Auction["start_date"] | null;
};

export type ProviderSearchPatient = {
  active_requests?: { careprovider_id: number; id: number }[];
  archived?: boolean;
  archived_at?: number;
  care_needs: Pick<
    PatientNeeds,
    | "basic_medical_care"
    | "base_care"
    | "psychiatric_care"
    | "palliative"
    | "breathing"
    | "static_care_disabled_patients"
  >;
  diagnosis: {
    mental_health?: Pick<MentalHealth, "dementia_description">;
  };
  id: number;
  profile?: Pick<
    PatientProfile,
    "age_interval" | "gender" | "care_duration_in_days" | "weight_interval"
  > & {
    financing?: Pick<Financing, "carelevel">;
  };
  profile_id: number;
  seald_encryption_context?: Patient["seald_encryption_context"];
  services?: number[];
  user_id?: Patient["user_id"];
};

export type ProviderSearchPatients = {
  active: ProviderSearchPatient[];
  archived: Omit<ProviderSearchPatient, "active_requests">[];
};

export type ProviderSearchProvider = {
  __typename?: string;
  address: CareproviderAddress;
  central_email?: string;
  central_phone_number?: string;
  cover_image_file?: Pick<CareproviderFile, "url" | "file_name">;
  distance: number;
  facilities?: number[] | null;
  id: number;
  is_consultant: boolean;
  name: string;
  opening_hours?: OpeningHours;
  other_remarks?: string;
  profile?: Pick<
    CareproviderProfile,
    "accepted_age" | "accepted_weight" | "capacity"
  > | null;
  project_sponsor?: string;
  services?: number[] | null;
  website?: string;
};

export type ProviderSearchActivation = {
  account: Account;
  auth_result: {
    account_id: string;
    id_token: string;
  };
  seald_two_man_rule_session_id: string;
};

export type LogoColors = (typeof LOGO_COLOR_SCHEMA)[number];

type PositiveDiffType = typeof NEGATIVE_DIFF;
type NegativeDiffType = typeof POSITIVE_DIFF;
type SimpleDiffType = typeof SIMPLE_DIFF;

export type PatientDiffType =
  | NegativeDiffType
  | PositiveDiffType
  | SimpleDiffType;

export type ProviderSearchListing = {
  cards?: Array<ProviderListing>;
  remaining: number;
  total: number;
};

export type ProviderSearchOverviewRequest = {
  action_required_by_user?: AuctionRequest["action_required_by_user"];
  auction?: Pick<
    Auction,
    "last_status_change" | "created_at" | "start_date" | "id"
  >;
  careprovider?: Pick<Careprovider, "address" | "name" | "id">;
  created_at?: AuctionRequest["created_at"];
  id: number;
  response?: Pick<AuctionResponse, "updated_at">;
  solutions?: AuctionRequest["solutions"];
  status?: AuctionRequest["status"];
  validation?: Pick<AuctionValidation, "updated_at">;
};

export type ProviderSearchRequestsOverview = {
  active: {
    requests: ProviderSearchOverviewRequest[] | undefined;
  } | null;
  inactive: {
    requests: ProviderSearchOverviewRequest[] | undefined;
  } | null;
  validated: {
    requests: ProviderSearchOverviewRequest[] | undefined;
  } | null;
};

export type PaginationResultCount = (typeof PAGINATION_RESULT_COUNTS)[number];

export type AgeRange = { max: number | null; min: number | null; name: string };

export type ConsultantStatus = (typeof CONSULTANT_STATUSES)[number];

export type Consultant = {
  address: CareproviderAddress;
  central_email?: string;
  central_phone_number?: string;
  description?: string;
  id: number;
  name: string;
  opening_hours?: OpeningHours;
  project_sponsor?: string;
  status?: ConsultantStatus;
  website?: string;
};

export type WeekDay = ValueOf<typeof WEEKDAYS>;

export type OpeningHours = {
  [index in WeekDay]?: string | null;
} & {
  __typename?: "OpeningHours";
};

export type ConsultantEvent = {
  account: Account;
  context: {
    diff: string[];
  };
  id: number;
  timestamp: number;
  type: (typeof CONSULTANT_EVENTS)[number];
};

export type ConsultantEventPage = {
  events: ConsultantEvent[];
  remaining: number;
  total: number;
};

export type LegalDocument = {
  needs_to_sign?: boolean;
  signed_at?: number;
  url: string;
  version?: string;
};

export type LegalDocumentType = (typeof LEGAL_DOCUMENTS)[number];

export type LegalDocuments = Record<LegalDocumentType, LegalDocument | null> & {
  __typename: "LegalDocuments";
};

export type GlossarySection = (typeof GLOSSARY_SECTIONS)[number];

export type RedirectReason =
  | typeof REDIRECT_REASON_GENERIC_ERROR
  | typeof REDIRECT_REASON_LINK_EXPIRED
  | typeof REDIRECT_REASON_NOT_AUTHORIZED;

export type TourElement =
  | Record<string, never>
  | {
      "data-tour-id": string;
      "data-tour-step": number;
    };

// intro.js
export type { IntroStep } from "intro.js/src/core/steps";
export type { Options as IntroOptions } from "intro.js/src/option";

export type TransitionalCareImportedAuctionRequest = {
  careprovider: {
    address: {
      address: string;
      city: string;
      zip_code: string;
    };
    id: number;
    name: string;
  };
  created_at: number;
  id: number;
  request_status: number;
};

export type GetAccountsForCareseekerPayload = {
  getAccountsForCareseeker: {
    accounts: Account[];
    total_accounts: number;
    total_filtered_accounts: number;
  };
};

export type GetAccountsForCareseekerQueryString =
  `search_term=${string}&start=${number}&count=${number}&sort_field=${string}&sort_order=${SortOrder}`;

export type GetAccountsForCareseekerVariables = {
  careseekerId: number;
  filters: GetAccountsForCareseekerQueryString;
};

export type ProductFeatureStatus = (typeof PRODUCT_FEATURE_STATUSES)[number];
export type ProductFeatureType = (typeof PRODUCT_FEATURES)[number];

export type ProductFeature = {
  expiration_date?: number;
  feature: ProductFeatureType;
  status: ProductFeatureStatus;
};

export type TransitionalCareRequest = {
  [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ADDRESS]: string;
  [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_CITY]: string;
  [REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ZIPCODE]: string;
  [REQUEST_FORM_FIELDS.CAREPROVIDER_NAME]: string;
  [REQUEST_FORM_FIELDS.REQUEST_SENT_DATE]: number | null;
  [REQUEST_FORM_FIELDS.REQUEST_SENT_TIME]: number | null;
  [REQUEST_FORM_FIELDS.REQUEST_STATUS]: number | null;
  edited: boolean;
  id: number;
  manual: boolean;
  request_id: number | null;
};

export type TransitionalCareFormData = {
  aftercare_application_date?: number;
  aftercare_application_explanation?: string;
  aftercare_application_submitted?: boolean;
  aftercare_care_level_aftercare_required?: boolean;
  aftercare_care_level_applied?: boolean;
  aftercare_care_level_applied_date?: number;
  aftercare_care_level_available?: boolean;
  aftercare_care_level_explanation?: string;
  aftercare_medical_rehab?: boolean;
  aftercare_mobile?: boolean;
  aftercare_shortterm_care?: boolean;
  aftercare_start_date?: number;
  care_necessity?: number;
  clinic_ik_number?: string;
  clinic_location_city?: string;
  clinic_location_street?: string;
  clinic_location_street_number?: string;
  clinic_location_zipcode?: string;
  clinic_name?: string;
  handover_to_insurance_date?: number;
  insurance_form_sent_date?: number;
  internet_portal_used?: boolean;
  legal_representative_consent_provided?: boolean;
  legal_representative_first_name?: string;
  legal_representative_last_name?: string;
  legal_representative_location_city?: string;
  legal_representative_location_street?: string;
  legal_representative_location_street_number?: string;
  legal_representative_location_zipcode?: string;
  patient_date_of_birth?: number;
  patient_first_name?: string;
  patient_insurance_number?: string;
  patient_last_name?: string;
  patient_location_city?: string;
  patient_location_house_number?: string;
  patient_location_street?: string;
  patient_location_zipcode?: string;
  prior_treatment_end_date?: number;
  prior_treatment_hospital_id?: string;
  prior_treatment_start_date?: number;
  request_list?: TransitionalCareRequest[];
  social_worker_email?: string;
  social_worker_fax?: string;
  social_worker_first_name?: string;
  social_worker_last_name?: string;
  social_worker_telephone?: string;
  transitional_care_department_code?: string;
  transitional_care_description?: string;
  transitional_care_end_date?: number;
  transitional_care_location_id?: string;
  transitional_care_patient_confirmation?: boolean;
  transitional_care_patient_confirmation_date?: number;
  transitional_care_patient_confirmation_explanation?: string;
  transitional_care_start_date?: number;
  transitional_care_start_search_date?: number;
  transitional_care_suitable_providers_count?: number;
  transitional_care_suitable_providers_more_than_twenty?: boolean;
};
